import { Component, Inject, Optional, ViewChild, untracked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BacktestConfigComponent } from '../backtest-config/backtest-config.component';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-backtest-config-dialog',
  templateUrl: './backtest-config-dialog.component.html',
  styleUrl: './backtest-config-dialog.component.scss'
})
export class BacktestConfigDialogComponent {
  @ViewChild(BacktestConfigComponent) config: BacktestConfigComponent | null = null;
  hasOperation = false;

  constructor(
    public dialogRef: MatDialogRef<BacktestConfigDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { prompt: any },
    @Optional() private toastr?: ToastrService
  )
  { }

  cancel(): void {
    this.dialogRef.close(false);
  }

  run(): void {
    const profile = untracked(this.authService.userProfile)
    const subscription = this.authService.userSubscription;

    if (profile.backtests >= subscription.maxBacktests) {
      this.toastr?.error(
        'You have reached your maximum of backtests per day. Please wait or \
        upgrade to increase capacity.',
        'Error triggering backtest'
      );
      return;
    }

    if (!this.config) {
      return;
    }

    this.hasOperation = true;
    this.config.run()?.then(
      (_) => {this.dialogRef.close(false);}
    ).catch(
      (_) => {
        this.toastr?.error(
          'Back-test configuration is invalid.',
          'Error triggering backtest'
        );
      }
    ).finally(() => {
      this.hasOperation = false;
    });
  }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SymbolService } from '../services/symbol.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { PromptService } from '../services/prompt.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  overview: any;
  selectedDate: Date | null = null;
  lastPriceDetails$: Observable<any> = this.symbolService.lastPriceDetails$;
  price$: Observable<any> = this.lastPriceDetails$.pipe(
    map((details) => details?.lastPrice)
  );
  priceChange$: Observable<any> = this.lastPriceDetails$.pipe(
    map((details) => details?.priceChange)
  );
  priceChangePercent$: Observable<any> = this.lastPriceDetails$.pipe(
    map((details) => details?.priceChangePercent)
  );
  positive$: Observable<any> = this.priceChange$.pipe(
    map((change) => change > 0)
  );
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private symbolService: SymbolService,
    private promptService: PromptService,
    private changeDetector: ChangeDetectorRef)
  {}

  ngOnInit(): void {
    this.symbolService.symbolDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((details: any) => {
        this.overview = details;
      });

    // Subscribe to the route params and select the symbol and prompt
    // based on the route params
    // This cannot be done in a service because the route params are
    // specific to the component
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.symbolService.select(params['symbol']);
      this.promptService.select({ promptId: params['promptId'] });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.symbolService.select(undefined);
    this.promptService.select({ promptId: undefined });
  }

  onDateSelected(selectedDate: Date) {
    this.selectedDate = selectedDate;
    this.changeDetector.detectChanges();
  }
}

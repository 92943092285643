<div class="description"><b>Description:</b> {{ prompt?.description }}</div>
<mat-list>

  <mat-list-item>
    <span matListItemTitle>Annualized Return</span>
    <span>{{ (performance$ | async)?.annualized_return | percent:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Excess Annualized Return</span>
    <span>{{ (performance$ | async)?.annualized_excess_return | percent:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Alpha</span>
    <span>{{ (performance$ | async)?.alpha | number:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Beta</span>
    <span>{{ (performance$ | async)?.beta | number:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Sharpe ratio</span>
    <span>{{ (performance$ | async)?.sharpe_ratio | number:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Maximum Draw Down</span>
    <span>{{ (performance$ | async)?.maximum_drawdown | percent:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Average Position</span>
    <span>{{ (performance$ | async)?.average_position | number:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Average Absolute Position</span>
    <span>{{ (performance$ | async)?.average_abs_position | number:'1.0-2' }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Run Time</span>
    <span matListItemLine>{{ (run$ | async)?.config?.run_time }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Count</span>
    <span matListItemLine>{{ (backtestConfig$ | async)?.eval_count }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Evaluation Day</span>
    <span matListItemLine>{{ (backtestConfig$ | async)?.eval_day }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Frequency</span>
    <span matListItemLine>{{ (backtestConfig$ | async)?.eval_freq }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Start</span>
    <span matListItemLine>{{ (backtestConfig$ | async)?.trigger_date }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Model</span>
    <span matListItemLine>{{ (llmConfig$ | async)?.model }}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>Temperature</span>
    <span matListItemLine>{{ (llmConfig$ | async)?.temperature }}</span>
  </mat-list-item>
</mat-list>

<div *ngIf="hasErrors" class="errors">
  <b class="error-title">Errors</b>
  <div class="error-entry" *ngFor="let error of errors | keyvalue">
    <b>{{ error.key | date:'short' }}</b><br>
    {{ error.value }}
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-risk-warning',
  standalone: true,
  imports: [],
  templateUrl: './risk-warning.component.html',
  styleUrl: './risk-warning.component.scss'
})
export class RiskWarningComponent {

}

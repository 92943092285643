<br>
<form [formGroup]="runConfigForm">
  <mat-form-field>
    <mat-label>Run Time</mat-label>
    <input matInput type="time" formControlName="runTime">
    <!-- <input matInput [matDatepicker]="runTimePicker" formControlName="runTime">
    <mat-datepicker-toggle matSuffix [for]="runTimePicker"></mat-datepicker-toggle>
    <mat-datepicker #runTimePicker></mat-datepicker> -->
  </mat-form-field>

  <div formGroupName="backtestConfig">
    <mat-form-field matTooltip="Number of prompt evaluations">
      <mat-label>Count</mat-label>
      <input matInput type="number" formControlName="eval_count" min="1" max="60">
    </mat-form-field>

    <mat-form-field matTooltip="Evaluation frequence (daily, weekly, monthly)">
      <mat-label>Frequency</mat-label>
      <mat-select formControlName="eval_freq">
        <mat-option *ngFor="let option of evalFreqOptions" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field matTooltip="Day of the week or month where the prompt is evaluated">
      <mat-label>Evaluation Day</mat-label>
      <mat-select formControlName="eval_day">
        <mat-option *ngFor="let day of daysOptions" [value]="day.value">
          {{ day.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field matTooltip="Date on which the back-test will end">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="triggerDatePicker" formControlName="trigger_date">
      <mat-datepicker-toggle matSuffix [for]="triggerDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #triggerDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div formGroupName="llmConfig">
    <mat-form-field>
      <mat-label>Temperature</mat-label>
      <input matInput type="number" min="0" max="10" step="0.01" formControlName="temperature">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Model</mat-label>
      <input matInput formControlName="model">
    </mat-form-field>
  </div>
</form>

<div class="chart-container">
  @if(isZoomedIn) {
  <button (click)="resetZoom()">Reset zoom</button>
  }

  <apx-chart
    #chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [colors]="chartOptions.colors"
    [yaxis]="chartOptions.yaxis"
    [dataLabels]="chartOptions.dataLabels"
    [markers]="chartOptions.markers"
    [stroke]="chartOptions.stroke"
    [grid]="chartOptions.grid"
    [xaxis]="chartOptions.xaxis"
    [tooltip]="chartOptions.tooltip"
    [annotations]="chartOptions.annotations"
  ></apx-chart>
</div>

<div class="buttons-container">
  @if(selectedPromptXaxis.xaxis) {
  <button
    (click)="updateOptions(FilterOptions.Prompt)"
    [class.active]="activeOptionButton == FilterOptions.Prompt"
  >
    Prompt
  </button>
  }

  <button
    (click)="updateOptions(FilterOptions.OneMonth)"
    [class.active]="activeOptionButton == FilterOptions.OneMonth"
  >
    1 Month
  </button>
  <button
    (click)="updateOptions(FilterOptions.ThreeMonths)"
    [class.active]="activeOptionButton == FilterOptions.ThreeMonths"
  >
    3 Months
  </button>
  <button
    (click)="updateOptions(FilterOptions.SixMonths)"
    [class.active]="activeOptionButton == FilterOptions.SixMonths"
  >
    6 Months
  </button>
  <button
    (click)="updateOptions(FilterOptions.OneYear)"
    [class.active]="activeOptionButton == FilterOptions.OneYear"
  >
    1 Year
  </button>
  <button
    (click)="updateOptions(FilterOptions.All)"
    [class.active]="activeOptionButton == FilterOptions.All"
  >
    All
  </button>
</div>

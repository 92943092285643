import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-blog-1-netflix',
  templateUrl: './blog-1-netflix.component.html',
  styleUrl: './blog-1-netflix.component.scss'
})
export class Blog1NetflixComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    (window as any).CUSDIS?.initial();
  }
}

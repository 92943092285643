import { Component, Input } from '@angular/core';
import { Prompt } from '../../../model/prompt.model';
import { RunResult, PerformanceMetrics, BacktestParams, LLMParams } from '../../../model/run.model';
import { RunService } from '../../../services/run.service';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-backtest-run',
  templateUrl: './backtest-run.component.html',
  styleUrl: './backtest-run.component.scss',
})
export class BacktestRunComponent {
  _prompt: Prompt | undefined;
  run$: Observable<RunResult | undefined> = of(undefined);
  performance$: Observable<PerformanceMetrics | undefined> = of(undefined);
  backtestConfig$: Observable<BacktestParams | undefined> = of(undefined);
  llmConfig$: Observable<LLMParams | undefined> = of(undefined);

  get prompt(): Prompt | undefined {
    return this._prompt;
  }

  @Input() set prompt(value: Prompt | undefined) {
    this._prompt = value;

    this.run$ = this.runService.getRun$(this.prompt?.promptId);
    this.performance$ = this.run$.pipe(map((run) => run?.performance?.all));
    this.backtestConfig$ = this.run$.pipe(map((run) => run?.config?.backtest_config));
    this.llmConfig$ = this.run$.pipe(map((run) => run?.config?.llm_config));
  }

  get hasErrors(): boolean {
    return Object.keys(this.prompt?.errors || {}).length > 0;
  }

  get errors(): { [key: string]: string } {
    return this.prompt?.errors || {};
  }

  constructor(private runService: RunService) {
  }
}

import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHttpService {

  constructor(private functions: Functions) { }

  call(functionName: string, data: any) {
    return httpsCallable(this.functions, functionName)(data);
  }
}

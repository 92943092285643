import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './details/details.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { TermsAndConditionsComponent } from './legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { RiskWarningComponent } from './legal/risk-warning/risk-warning.component';
import { PricingComponent } from './home/pricing/pricing.component';
import { AboutComponent } from './home/about/about.component';
import { GaHelpComponent } from './home/ga-help/ga-help.component';
import { FeedbackCannyComponent } from './components/feedback-canny.component';
import { GaHomeComponent } from './home/ga-home.component';
import { ProfileComponent } from './profile/profile.component';
import { PromptsOverviewComponent } from './admin/prompts-overview/prompts-overview.component';
import { NotAuthorizedComponent } from './legal/not-authorized/not-authorized.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { BlogComponent } from './blog/blog.component';
import { Blog1NetflixComponent } from './blog/blog-1-netflix/blog-1-netflix.component';

const routes: Routes = [
  { path: '', component: GaHomeComponent },
  { path: 'search', component: GaHomeComponent },
  { path: 'ticker/:symbol', component: DetailsComponent },
  { path: 'ticker/:symbol/:promptId', component: DetailsComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'about', component: AboutComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'help', component: GaHelpComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'admin', component: PromptsOverviewComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'risk-warning', component: RiskWarningComponent },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/analyzing-netflix-stock', component: Blog1NetflixComponent },
  {
    path: 'feedback',
    component: FeedbackCannyComponent,
    children: [{
      path: '*',
      component: FeedbackCannyComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

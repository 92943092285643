import { Injectable } from '@angular/core';
import { setConsent, ConsentSettings } from '@angular/fire/analytics'
import { CookieService } from 'ngx-cookie-service';

type ConsentLevel = 'necessary' | 'functionality' | 'tracking' | 'targeting';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private consent: ConsentSettings = {
    ad_storage: 'denied',
    ad_personalization: 'denied',
    ad_user_data: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted'
  };

  constructor(
    private cookieService: CookieService
  ) {
    setConsent(this.consent);
  }

  public updateConsent(level: ConsentLevel): void {
    const hasConsentCookie = this.cookieService.check('cookie_consent_level');
    if (hasConsentCookie) {
      const cookie = JSON.parse(this.cookieService.get('cookie_consent_level'));
      if ( cookie["functionality"] ) {
        this.consent.functionality_storage = 'granted';
      } else {
        this.consent.functionality_storage = 'denied';
      };

      if ( cookie["tracksing"] ) {
        this.consent.analytics_storage = 'granted';
        this.consent.personalization_storage = 'granted';
      } else {
        this.consent.analytics_storage = 'denied';
        this.consent.personalization_storage = 'denied';
      };

      if ( cookie["targeting"] ) {
        this.consent.ad_storage = 'granted';
        this.consent.ad_personalization = 'granted';
        this.consent.ad_user_data = 'granted';
      } else {
        this.consent.ad_storage = 'denied';
        this.consent.ad_personalization = 'denied';
        this.consent.ad_user_data = 'denied';
      };
    }

    setConsent(this.consent);
  }
}

import { Injectable, Optional } from '@angular/core';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from '@angular/fire/firestore';
import { db } from 'src/environments/environment';
import { BehaviorSubject, Observable, skip } from 'rxjs';
import { PredictionService } from './prediction.service';
import { ToastrService } from 'ngx-toastr';

export interface PredictionRelevancyFilter {
  startDate: string;
  endDate: string;
  performance: string;
  positionChange: boolean;
  method: string;
}

@Injectable({
  providedIn: 'root'
})
export class PredictionRelevancyService {
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  lastDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  relevanciesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  relevancies$: Observable<any[]> = this.relevanciesSubject.asObservable();
  lastVisible: any = {};

  constructor(
    private predictionService: PredictionService,
    @Optional() private toastr?: ToastrService) {
      this.loadLastDate();
    }

  async loadLastDate() {
    const relevanciesQuery = query(
      collection(db, 'relevancies'),
      orderBy('date', 'desc'),
      limit(2)
    );
    const querySnapshot = await getDocs(relevanciesQuery);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[1].data();
      const lastDate = new Date(latestDoc['date']);
      this.lastDate.next(lastDate);
    }
  }

  load(filter: PredictionRelevancyFilter, reset: boolean = false) {
    if (reset) {
      this.lastVisible = {};
    }

    this.loading.next(true);
    const promptsRef = collection(db, "relevancies");
    let constraints: any[] = [];

    if (filter.method) {
      constraints.push(where("method", "==", filter.method));
    }
    if (filter.startDate) {
      constraints.push(where('date', '>=', filter.startDate));
    }
    if (filter.endDate) {
      constraints.push(where('date', '<=', filter.endDate));
    }
    if (filter.performance === 'Winners') {
      constraints.push(where('annualized_excess_return', '>', 0));
    }
    if (filter.performance === 'Losers') {
      constraints.push(where('annualized_excess_return', '<', 0));
    }
    if (filter.positionChange) {
      constraints.push(where('position_change', '==', true));
    }

    constraints = [
      ...constraints,
      orderBy('date', 'desc'),
      orderBy('relevance', 'desc'),
      startAfter(this.lastVisible),
      limit(6)
    ]

    const q = query(
      promptsRef,
      ...constraints
    );

    getDocs(q).then(async (snapshot) => {
      let relevancies: any[] = reset ? [] : this.relevanciesSubject.value;

      // Somehow the query doesn't return any results, even though the constraints should be valid
      if (this.relevanciesSubject.value.length === 0 && snapshot.empty) {
        console.warn("Error: no news found", constraints);
        // this.toastr?.warning('Error: no news found', JSON.stringify(constraints));
        return;
      }

      for (const doc of snapshot.docs) {
        if (relevancies.some(r => r.relevancyId === doc.id)) {
          continue;
        }

        const data: any = doc.data();

        const prediction = await this.predictionService.loadPrediction(data.prediction_id);
        if (!prediction) {
          console.error("Prediction not found for relevancy", data);
          this.toastr?.error(data.prediction_id, 'Error loading prediction relevancies');
          continue;
        }

        const r = data.annualized_excess_return;

        const relevancy = {
          ...data,
          ...prediction,
          annualized_excess_return: r ? (100 * r).toFixed(1) : null,
          relevancyId: doc.id,
        };
        relevancies.push(relevancy);
        this.lastVisible = doc;
      }

      this.relevanciesSubject.next(relevancies);
    }).catch((error) => {
      console.error("Error getting documents: ", error);
      this.toastr?.error(error, 'Error loading prediction relevancies');
    }) .finally(() => {
      this.loading.next(false);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ConsentService } from './legal/consent/consent.service';
import { AnalyticsService } from './services/analytics.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gpt-analyst';

  constructor(
    _: ConsentService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    (window as any).setConsent = this.analyticsService.updateConsent;
  }
}

<h2>Getting started</h2>
<p>Watch a quick video to see how to create your first prompt.</p>
<div style="display: flex; justify-content: center; align-items: center; padding: 1%;">
<div style="overflow: hidden; aspect-ratio: 1920/1080; width: 60%;"><iframe src="https://share.synthesia.io/embeds/videos/752abe9d-d4fd-4d97-8598-8732241e3a6a" loading="lazy" title="Synthesia video player - GPT Analyst: How to write a prompt" allowfullscreen allow="encrypted-media; fullscreen;" style="display: flex; width: 100%; height: 100%; padding: 0; margin: 0; overflow:hidden;"></iframe></div>
</div>

<h2 style="padding-top: 24px;">Frequently Asked Questions</h2>
<div>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>How is prompt performance calculated?</mat-panel-title>
    </mat-expansion-panel-header>
    <p>Prompt performance is computed by simulating a trade event on the next available market open / close. That means that e.g. a prompt for a symbol traded on the NSYE evaluated at 2pm on a Monday afternoon will be executed at 4 pm that day. If the prompt is evaluated after market close, it will be traded on open the next day.</p>
    <br>
    <p>This <i>also</i> means that a prompt evaluated after market close on a Friday evening will not be traded until the next Monday morning. Should additional prompt evaluations occur prior to the next open (e.g. on Saturday and Sunday) only the last one will be executed.</p>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>How can I upgrade my account?</mat-panel-title>
    </mat-expansion-panel-header>
    <p>We are actively working on this functionality. Stay tuned!</p>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>I cannot find symbol XYZ.</mat-panel-title>
    </mat-expansion-panel-header>
    <p>Sorry about that! We are regularly adding new data sources, hopefully your favourite stock will be available soon. Head over to our <a routerLink="/feedback">contact</a> page to let us know what you're looking for.</p>
  </mat-expansion-panel>
</div>

@switch (status) {
  @case ('running') {
    <mat-progress-bar
      mode="buffer"
      title="Backtest running..."
      [value]="progress">
    </mat-progress-bar>
    <div class="progress-percent" *ngIf="progress > 0">
      {{ progress | number:'1.1-1' }}%
    </div>
  }
  <!-- This is super ugly, can running and interrupted be caught together? -->
  @case ('interrupted') {
    <mat-progress-bar
      mode="buffer"
      title="Backtest running..."
      [value]="progress">
    </mat-progress-bar>
    <div class="progress-percent" *ngIf="progress > 0">
      {{ progress | number:'1.1-1' }}%
    </div>
  }
  @case ('complete') {
    <mat-icon
      title="Backtest completed"
      class="backtest complete">
      check
    </mat-icon>
  }
  @case ('failed') {
  <mat-icon
    title="Backtest failed"
    class="backtest failed">
    manage_history
  </mat-icon>
  }
  @case ('untested') {
  <mat-icon
    title="Untested"
    class="backtest untested">
    do_not_disturb
  </mat-icon>
  }
  @case ('queued') {
  <mat-icon
    title="Queued"
    class="backtest queued">
    schedule
  </mat-icon>
  }
}

import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  searchTermSubject: Subject<string> = new Subject<string>();
  searchResults: any[] = [];
  searching: boolean = false;
  query: string = '';
  private destroy$ = new Subject<void>();

  constructor(
    private functions: Functions,
    @Optional() private toastr?: ToastrService)
  {}

  ngOnInit(): void {
    this.searchTermSubject
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        this.searchForTicker(value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSearch(event: any) {
    this.searchTermSubject.next(event);
  }

  searchForTicker(searchTerm: string) {
    this.query = searchTerm;

    if (!searchTerm) {
      this.searchResults = [];
      return;
    }

    this.searching = true;
    httpsCallable(
      this.functions,
      'suggest_ticker'
    )({ searchTerm: searchTerm })
      .then((result) => {
        const data: any[] = result.data as any[];
        this.searchResults = data.map((match: any) => {
          return {
            symbol: match['symbol'],
            name: match['name'],
            type: match['type'],
            region: match['country'],
            currency: match['currency'],
          };
        });
      })
      .catch((error) => {
        console.error(error);
        this.toastr?.error(error, "Error searching ticker");
      })
      .finally(() => {
        this.searching = false;
      });
  }
}

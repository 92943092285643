<div class="profile-container">
  <div class="profile-header">
    <div class="avatar">
      <img [src]="user?.photoURL" alt="Avatar">
    </div>
    <div class="profile-info">
      <div class="name">{{ user?.displayName || 'Unkonwn Name' }}</div>
      <div class="email">{{ user?.email || 'Unkonwn Email' }}</div>
      <div class="subscription">Subscription: {{ subscription?.name || 'N/A' }}</div>
    </div>
  </div>

  <div class="profile-details">
    <table>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Maximum Allowed</th>
          <th>Current Usage</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Backtests per Day</td>
          <td>{{ subscription.maxBacktests }}</td>
          <td>{{ userProfile.backtests }}</td>
        </tr>
        <tr>
          <td>Active Backtests</td>
          <td>{{ subscription.maxActiveBacktests }}</td>
          <td>{{ userProfile.active_backtests }}</td>
        </tr>
        <tr>
          <td>Previews per Day</td>
          <td>{{ subscription.maxPreviews }}</td>
          <td>{{ userProfile.previews }}</td>
        </tr>
        <tr>
          <td>Prompts</td>
          <td>{{ subscription.maxPrompts }}</td>
          <td>{{ userProfile.prompts }}</td>
        </tr>
        <tr>
          <td>Active Prompts</td>
          <td>{{ subscription.maxActive }}</td>
          <td>{{ userProfile.active_runs }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

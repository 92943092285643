import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SymbolService } from 'src/app/services/symbol.service';

@Component({
  selector: 'app-metrics-overview',
  templateUrl: './metrics-overview.component.html',
  styleUrl: './metrics-overview.component.scss'
})
export class MetricsOverviewComponent {

  data$: Observable<any> = this.symbolService.symbolDetails$;
  open: boolean = false;

  constructor(private symbolService: SymbolService) { }

  toggle() {
    this.open = !this.open;
  }
}

import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, map, combineLatest } from 'rxjs';
import { PromptsService } from './prompts.service';
import { RunConfiguration } from '../model/run.model';
import { FirebaseHttpService } from './firebase-http.service';
import { ToastrService } from 'ngx-toastr';
import { RunService } from './run.service';
import { PredictionService } from './prediction.service';
import { Prompt } from '../model/prompt.model';

@Injectable({
  providedIn: 'root',
})
export class PromptService {
  promptIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  promptId$: Observable<string> = this.promptIdSubject.asObservable();
  prompt$: Observable<Prompt | undefined> = combineLatest([this.promptsService.prompts$, this.promptId$]).pipe(
    map(([prompts, promptId]) => prompts.find((prompt) => prompt.promptId === promptId))
  );

  promptId: string | undefined = undefined;
  selectedDate: string | undefined = undefined;

  hasOperation: boolean = false;

  get promptPerformance$ (): Observable<any> {
    return this.runService.promptPerformance$;
  }

  get promptPositions$ (): Observable<any> {
    return this.runService.promptPositions$;
  }

  constructor(
    private fbHttps: FirebaseHttpService,
    private promptsService: PromptsService,
    private predictionService: PredictionService,
    private runService: RunService,
    @Optional() private toastr?: ToastrService) {

    // Load run when a prompt is selected
    this.prompt$.subscribe((prompt: Prompt | undefined) => {
      if (prompt) {
        this.loadRun(prompt);
      }
    });

    // When no prompt is selected in the route, select the first starred prompt
    // or first prompt when the prompts are loaded
    this.promptsService.prompts$.subscribe((prompts) => {
      if (!this.promptIdSubject.value && prompts.length) {
        const selectePrompt = prompts.find((prompt) => prompt.starred) ?? prompts[0];
        this.select(selectePrompt);
      }
    });
  }

  select(prompt: any) {
    const promptId = prompt.promptId;
    if (this.promptIdSubject.value === promptId) return;

    this.runService?.clear();
    this.predictionService?.clear();
    this.promptIdSubject.next(promptId);

    // New prompts have an undefined Id
    // We don't want to load anything for them
    if (!promptId) return;

    // // Load the last prediction for the prompt
    // this.predictionService
    //   .loadPrediction(prompt.last_prediction_id)
    //   .then((prediction) => {
    //     // console.log('Loaded last prediction:', prediction);
    //     // TODO: initialize the predictions in the run service
    //     // to actually speed up the display
    //   });
  }

  loadRun(prompt: Prompt, setPositions: boolean = true) {
    this.runService?.load(prompt, setPositions);
  }

  delete(prompt: Prompt) {
    this.fbHttps
      .call('delete_prompt', { promptId: prompt.promptId })
      .catch((error) => {
        console.log('Error deleting prompt:', prompt.promptId, error);
        this.toastr?.error(error, 'Error deleting prompt');
      });
  }

  backtest(promptId: string, runConfig: RunConfiguration) {
    if (!promptId) return;

    const data = {
      promptId: promptId,
      runConfig: runConfig
    };

    this.promptsService.initializeBacktest(data);
    return this.fbHttps
      .call('trigger_backtest', data)
      .then((result: any) => {
        console.log('Triggered backtest. Run Id', result, data);
      }).catch((error) => {
        console.error('Error triggering backtest:', error.message);
        this.promptsService.failBacktest(promptId);
        this.toastr?.error(error.message, 'Error triggering backtest');
        throw error;
      });
  }

  toggleActive(prompt: Prompt) {
    if (!prompt?.promptId) return;

    const id: string = prompt.promptId;
    const currentStatus = prompt.active;
    this.promptsService.setActive(prompt.promptId, !prompt.active);

    this.fbHttps
      .call('toggle_active', { promptId: id })
      .then((result: any) => {
        result = result.data;
        this.promptsService.setActive(id, result.active);
      }).catch((error) => {
        this.promptsService.setActive(id, currentStatus);
        console.error('Error toggling status:', error);
        this.toastr?.error(error, 'Error toggling status');
      });
  }
}

import { Component, effect } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isAdmin: boolean = false;

  constructor(public authService: AuthService) {
    effect(() => {
      const user = authService.user() ?? null;
      user?.getIdTokenResult().then((token) => {
        this.isAdmin = token.claims['admin'] as boolean;
      });
    });
  }
}

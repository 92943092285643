import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SymbolService } from 'src/app/services/symbol.service';

@Component({
  selector: 'app-home-overview-tabs',
  templateUrl: './home-overview-tabs.component.html',
  styleUrl: './home-overview-tabs.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomeOverviewTabsComponent {
  markets: any = [];
  displayedColumns: string[] = ['name', 'last', 'high', 'low', 'change', 'change-percent', 'volume', 'time', 'status'];

  constructor(
    private symbolService: SymbolService,
    private router: Router,
    @Optional() private toastr?: ToastrService) {

    this.symbolService.loadOverview().then((data: any) => {
      if ( data.exists() ) {
        this.markets = data.data().data;
      } else {
        this.markets = [];
      }
    }).catch((error) => {
      console.error(error);
      this.toastr?.error(error, "Error");
    });
  }

  goTo(row: any) {
    this.router.navigate(['ticker', row.symbol])
  }
}

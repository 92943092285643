import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject, debounceTime, of, takeUntil } from 'rxjs';
import { PredictionService } from 'src/app/services/prediction.service';
import { PromptService } from 'src/app/services/prompt.service';
import { DateTime } from "luxon";

@Component({
  selector: 'app-prompt-viewing',
  templateUrl: './prompt-viewing.component.html',
  styleUrl: './prompt-viewing.component.scss',
})
export class PromptViewingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedDate: Date | null = null;
  currentIndex = 0;
  carouselItems: any[] = [];
  prediction$: Observable<any> = of({});
  prompt$: Observable<any> = of({});
  showPrompt: boolean = false;
  private destroy$ = new Subject<void>();

  get n(): number {
    return this.carouselItems.length;
  }

  get isFirst(): boolean {
    return this.currentIndex === 0;
  }

  get isLast(): boolean {
    return this.currentIndex === this.carouselItems.length - 1;
  }

  get decision(): string {
    return this.carouselItems[this.currentIndex]?.decision;
  }

  constructor(
    private promptService: PromptService,
    private predictionService: PredictionService) {
    this.prediction$ = this.predictionService.prediction$;
    this.prompt$ = this.promptService.prompt$;
  }

  ngOnInit(): void {
    this.promptService.promptPositions$
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(50))
      .subscribe((positions: any) => {
        this.carouselItems = positions;
        this.setIndex(this.carouselItems.length - 1);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDate'] && this.selectedDate) {
      this.updateIndexByDate(this.selectedDate);
    }
  }

  navigate(forward: boolean) {
    if (forward) {
      this.setIndex((this.currentIndex + 1) % this.carouselItems.length);
    } else {
      this.setIndex((this.currentIndex - 1) % this.carouselItems.length);
    }
  }

  setIndex(index: number) {
    this.currentIndex = index;
    const prediction = this.carouselItems[this.currentIndex];
    this.predictionService.setPrediction(
      prediction?.prediction_id,
      prediction?.prediction_date
    );

    // Pre-load
    if (this.currentIndex > 0) {
      this.predictionService.loadPrediction(
        this.carouselItems[this.currentIndex - 1]?.prediction_id
      );
    }

    if (this.currentIndex < this.carouselItems.length - 1) {
      this.predictionService.loadPrediction(
        this.carouselItems[this.currentIndex + 1]?.prediction_id
      );
    }
  }

  togglePrompt() {
    this.showPrompt = !this.showPrompt;
  }

  transformDate(date: string): string {
    if (!date) return '';
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
  }

  private updateIndexByDate(selectedDate: Date) {
    const targetDateTime = selectedDate.getTime();

    let closestIndex = -1;
    let closestDifference = Infinity;

    this.carouselItems.forEach((item, index) => {
      const itemDateTime = new Date(item.prediction_date).getTime();

      // Prediction date cannot be after execution date
      if (itemDateTime > targetDateTime) return;

      const difference = Math.abs(itemDateTime - targetDateTime);
      if (difference < closestDifference) {
        closestDifference = difference;
        closestIndex = index;
      }
    });

    if (closestIndex !== -1) {
      this.setIndex(closestIndex);
    }
  }
}

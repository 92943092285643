import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import {
  ChartComponent,
  PointAnnotations,
  ApexOptions,
  ApexXAxis,
  ApexAxisChartSeries,
} from 'ng-apexcharts';
import { Subject, takeUntil, combineLatest, debounceTime, BehaviorSubject } from 'rxjs';
import { PredictionService } from '../../services/prediction.service';
import { PromptService } from '../../services/prompt.service';
import { SymbolService } from '../../services/symbol.service';
import { FilterOptions } from './chart-filter-mode.model';
import { DateTime } from 'luxon';
import { RunService } from '../../services/run.service';
import { Platform } from '@angular/cdk/platform';


@Component({
  selector: 'app-interactive-chart',
  standalone: false,
  templateUrl: './interactive-chart.component.html',
  styleUrl: './interactive-chart.component.scss',
})
export class InteractiveChartComponent implements OnInit, OnDestroy {
  private chartUpdateSubject = new BehaviorSubject<any>({});

  @ViewChild('chart', { static: false }) chart!: ChartComponent;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();

  public chartOptions: Partial<ApexOptions> | any = {};
  public activeOptionButton = FilterOptions.Prompt;
  public readonly FilterOptions = FilterOptions;
  public isZoomedIn = false;
  public updateOptionsData: any = {
    [FilterOptions.OneMonth]: {
      xaxis: {
        type: 'datetime',
        min: moment().subtract(1, 'months').startOf('day').valueOf(),
        max: moment().endOf('day').valueOf(),
      },
    },
    [FilterOptions.ThreeMonths]: {
      xaxis: {
        type: 'datetime',
        min: moment().subtract(3, 'months').startOf('day').valueOf(),
        max: moment().endOf('day').valueOf(),
      },
    },
    [FilterOptions.SixMonths]: {
      xaxis: {
        type: 'datetime',
        min: moment().subtract(6, 'months').startOf('day').valueOf(),
        max: moment().endOf('day').valueOf(),
      },
    },
    [FilterOptions.OneYear]: {
      xaxis: {
        type: 'datetime',
        min: moment().subtract(1, 'years').startOf('day').valueOf(),
        max: moment().endOf('day').valueOf(),
      },
    },
    [FilterOptions.FiveYears]: {
      xaxis: {
        type: 'datetime',
        min: moment().subtract(5, 'years').startOf('day').valueOf(),
        max: moment().endOf('day').valueOf(),
      },
    },
    [FilterOptions.All]: {
      xaxis: {
        type: 'datetime',
        min: undefined,
        max: undefined,
      },
    },
  };
  public selectedPromptXaxis: { xaxis: ApexXAxis | undefined } = {
    xaxis: undefined,
  };
  private destroy$ = new Subject<void>();
  private priceSeries: any = { data: [] };
  private promptSeries: any = { data: [] };
  private promptPositions: any = [];
  private selectedPredictionId: any = {};
  private selectedPredictionDate: any = undefined;

  constructor(
    private symbolService: SymbolService,
    private promptService: PromptService,
    private predictionService: PredictionService,
    private runService: RunService,
    private changeDetector: ChangeDetectorRef,
    private platform: Platform)
  {}

  public ngOnInit(): void {
    this.initChart([], []);

    combineLatest([
      this.symbolService.symbolPerformance$,
      this.symbolService.symbolMarketInfo$
    ])
      .pipe(takeUntil(this.destroy$))
      // Avoid updating the chart too frequently
      .pipe(debounceTime(250))
      .subscribe(([data, marketInfo]) => {
        const priceData: number[][] = Object.entries(data)
          .map(([dateString, dataObj]: [string, any]) => {
            const timestamp = DateTime.fromFormat(dateString + marketInfo["close"] + marketInfo["timezone"], "yyyy-MM-ddHH:mmz").toJSDate().getTime();
            const closeValue = parseFloat(dataObj['5. adjusted close']);
            return [timestamp, closeValue];
          })
          .slice(-5 * 250);

        this.priceSeries = this.getPriceSeries(priceData);
        const series = [
          {...this.priceSeries},
          {...this.promptSeries}
        ];
        this.chartUpdateSubject.next({
          series: series,
          ...this.getAxisOptions()
        });
      });

    this.promptService.promptPositions$
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(1))
      .subscribe((positions) => {
        this.promptPositions = positions;
        this.updatePromptData();
      });

    combineLatest([this.predictionService.predictionId$, this.predictionService.predictionDate$])
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(1))
      .subscribe(([id, date]) => {
        if (this.selectedPredictionId === id && this.selectedPredictionDate == date) return;

        this.selectedPredictionId = id;
        this.selectedPredictionDate = date;
        this.updatePromptData();
      });

    this.promptService.promptPerformance$
      .pipe(takeUntil(this.destroy$))
      // Avoid updating the chart too frequently
      .pipe(debounceTime(500))
      .subscribe((data) => {
        const promptData: number[][] = data.map((p: any) => {
          return [p.x.getTime(), p.y];
        });

        if (promptData.length) {
          this.selectedPromptXaxis = {
            xaxis: {
              min: moment(data[0].x).valueOf(),
              max: moment(data[promptData.length - 1].x).valueOf(),
            },
          };
        }

        this.promptSeries = this.getPromptSeries(promptData);
        const axisOptions = this.getAxisOptions();
        const promptAnnotations = this.getPromptAnnotations(promptData);
        const options = {
          series: [
            {...this.priceSeries},
            {...this.promptSeries}
          ],
          ...axisOptions,
          annotations: {
            points: promptAnnotations,
          }
        };
        this.chartUpdateSubject.next(options);
      });

    this.chartUpdateSubject
      .pipe(takeUntil(this.destroy$))
      .pipe(debounceTime(50))
      .subscribe(async options => {
        await this.timedExecution(
          async () => {
            try {
              await this.chart?.updateOptions(options);
            } catch (error) {
              // TODO: change library or understand problem
              // console.log('Failed to update chart options', error, options);
            }
          },
          "updateOptions"
        );
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updatePromptData(): void {
    if (!this.promptSeries.data) return;

    const data = [...this.promptSeries.data];
    const promptAnnotations = this.getPromptAnnotations(data);
    this.chartUpdateSubject.next({
      annotations: {
        points: promptAnnotations,
      },
    });
  }

  getPriceSeries(data: number[][]) {
    return {
      name: 'Stock Price',
      data: data,
      type: 'area',
      color: '#008FFB',
    };
  }

  getPromptSeries(data: number[][]) {
    return {
      name: 'Prompt Price',
      data: data,
      type: 'area',
      color: '#00a66e',
    };
  }

  getMarkerPosition(item: any) {
    const itemDates = this.runService.dateMap.get(item[0]);
    for (const position of this.promptPositions) {
      const date = new Date(position.prediction_date);
      const timestamp = date.getTime();
      if (timestamp === itemDates?.get("predictionDate")?.getTime()) {
        if(itemDates.get("executionDate") === null) {
          return [position, true];
        } else {
          return [position, false];
        }
      };
    }
    return [null, false];
  }

  getMarkerStyle(item: any): any {
    const [position, latest] = this.getMarkerPosition(item);
    const isSelected =
      position?.prediction_id === this.selectedPredictionId &&
      position?.prediction_date === this.selectedPredictionDate;

    let style = {
      fillColor: 'darkgray',
      size: isSelected ? latest ? 10 : 8 : latest ? 4 : 3,
      strokeWidth: isSelected ? 2 : 1,
      strokeColor: isSelected ? 'black' : 'gray',
      shape: latest ? 'square' : 'circle',
      radius: latest ? 0 : 3,
    };

    if (!position) {
      return style;
    }

    if (position.decision === 'long') {
      return {
        ...style,
        fillColor: '#00a66e'
      };
    }
    if (position.decision === 'short') {
      return {
        ...style,
        fillColor: '#ff7f7f'
      };
    }
    if (position.decision === 'cash') {
      return {
        ...style,
        fillColor: 'white'
      };
    }

    return style;
  }

  getPromptAnnotations(data: number[][]) {
    const handleDateSelection = (timestamp: number) => {
      const date = new Date(timestamp);
      this.dateSelected.emit(date);
    };

    return data.map((item, index) => ({
      x: item[0], // represents the timestamp
      y: item[1], // represents the value
      click: () => handleDateSelection(item[0]),
      marker: {
        ...this.getMarkerStyle(item),
        strokeOpacity: 0.8
      },
      // label: {
      //   style: {
      //     color: '#fff',
      //     background: '#1AC088',
      //   },
      //   text: 'N',
      //   click: () => handleDateSelection(item[0]), // clickable annotations for special events to see predictions
      // },
    })) as PointAnnotations[];
  }

  public initChart(chartData: number[][], promptData: number[][]): void {
    const priceSeries = [this.getPriceSeries(chartData)] as ApexAxisChartSeries;
    const promptSeries = this.selectedPromptXaxis.xaxis
      ? ([this.getPromptSeries(promptData)] as ApexAxisChartSeries)
      : [];

    this.chartOptions = {
      series: [...priceSeries, ...promptSeries],
      chart: {
        type: 'area',
        height: 320,
        zoom: {
          enabled: !this.isMobileDevice(),
          type: 'x',
          autoScaleYaxis: true,
        },
        events: {
          zoomed: (_, { xaxis, __ }) => {
            if (!this.isZoomedIn) {
              this.isZoomedIn = true;
              this.changeDetector.detectChanges();
            }

            this.updateYaxisOptions(xaxis);
          }
        },
        toolbar: {
          show: false,
          autoSelected: 'zoom',
        },
      },
      annotations: {
        points: this.getPromptAnnotations(promptData),
      },
      dataLabels: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      markers: {
        size: 0,
        strokeColors: '#0091ff',
        hover: {
          size: 2,
        },
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 10,
        tooltip: {
          enabled: true,
          offsetY: 20,
        },
      },
      yaxis: {
        show: true,
        decimalsInFloat: 0,
        min: 0,
        max: undefined,
        opposite: true,
        labels: {
          show: true,
        },
      },
      stroke: {
        show: true,
        curve: 'straight',
        width: 1,
      },
      tooltip: {
        enabled: true,
        x: {
          format: 'dd MMM yyyy',
          show: false,
        },
        y: {
          title: {
            formatter: (seriesName: string) => {
              return `${seriesName}:`;
            },
          },
          formatter: (val: number) => {
            return val?.toFixed(2);
          },
        },
        marker: {
          show: true,
        },
        followCursor: true,
        // custom: function({series, seriesIndex, dataPointIndex, w}) {
        //   return '<div>' +
        //     '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
        //     '</div>'
        // }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    } as Partial<ApexOptions>;
  }

  public getAxisOptions(option?: FilterOptions): Partial<ApexOptions> {
    option = option ?? this.activeOptionButton;
    this.activeOptionButton = option;
    if (!this.chart) return {};

    // Update the x-axis based on the selected option
    const xAxis = option === FilterOptions.Prompt
      ? this.selectedPromptXaxis.xaxis
        ? this.selectedPromptXaxis
        : this.updateOptionsData[FilterOptions.All]
      : this.updateOptionsData[option];

    // Update the y-axis based on the x-axis min and max values
    const yMinMax = this.computeYaxisMinMax(xAxis.xaxis.min, xAxis.xaxis.max);

    // Get the chart axis options
    const options = {
      ...xAxis,
      yaxis: {
        ...this.chartOptions.yaxis,
        min: yMinMax.min,
        max: yMinMax.max,
      },
      legend: {
        position: 'bottom',
        floating: false,
        /* Check Safari, Firefox and Chromium rendering before changing! */
        offsetY: this.platform.SAFARI ? 30 : 5
      }
    };
    return options;
  }

  public updateOptions(option?: FilterOptions): void {
    const axisOptions = this.getAxisOptions(option);
    if (!axisOptions) return;

    // Update the chart options
    this.chartUpdateSubject.next(axisOptions);
  }

  public updateYaxisOptions(xaxis: any): void {
    // Update the y-axis based on the x-axis min and max values
    const yMinMax = this.computeYaxisMinMax(xaxis.min, xaxis.max);

    // Update the chart options
    const options = {
      yaxis: {
        ...this.chartOptions.yaxis,
        min: yMinMax.min,
        max: yMinMax.max,
      }
    };
    this.chartUpdateSubject.next(options);
  }

  public computeYaxisMinMax(xMin: number, xMax: number): any {
    xMin = xMin ?? 0;
    xMax = xMax ?? new Date().getTime();
    const ys = [...this.priceSeries.data, ...this.promptSeries.data]
      .filter((d: any) => d[0] >= xMin && d[0] <= xMax)
      .map((d: any) => d[1]);

    if (!ys.length) {
      return {
        min: 0,
        max: undefined
      };
    }

    const min = Math.min(...ys);
    const max = Math.max(...ys);
    const diff = max - min;
    return {
      min: Math.max(0, min - 0.1 * diff),
      max: Math.max(0, max + 0.1 * diff),
    };
  }

  public resetZoom(): void {
    this.isZoomedIn = false;
    this.updateOptions(FilterOptions.All);
    this.changeDetector.detectChanges();
  }

  // Execute function and log duration of execution
  private async timedExecution(func: () => Promise<void>, name: string) {
    const start = performance.now();
    await func();
    const duration = performance.now() - start;
    if (duration > 1) {
      console.log(`${name} took ${duration}ms`);
    }
  }

  private isMobileDevice(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}

import { Component, Inject, OnDestroy, OnInit, Optional, untracked } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from '../../../components/confirmation-dialog/confirmation-dialog.component';
import { Prompt } from '../../../model/prompt.model';
import { AuthService } from '../../../services/auth.service';
import { PromptService } from '../../../services/prompt.service';
import { SymbolService } from '../../../services/symbol.service';

type TimeOption =
  | 'today'
  | 'yesterday'
  | 'last-week'
  | 'last-month'
  | 'last-quarter'
  | 'last-year';
enum Tabs {
  Definition = 'Definition',
  Preview = 'Preview',
}
@Component({
  selector: 'app-prompt-editing',
  templateUrl: './prompt-editing.component.html',
  styleUrl: './prompt-editing.component.scss',
})
export class PromptEditingComponent implements OnInit, OnDestroy {
  prompt: any = {
    promptId: undefined,
    target_ticker: undefined,
    name: '',
    description: '',
    template_body: '',
  };

  symbol: string | undefined;
  executionDate: TimeOption = 'today';
  promptWithInputs = '';
  executedPrompt = '';
  hasOperation = false;
  selectedTabIndex = 0;
  openHeader = false;
  openFooter = false;

  public readonly Tabs = Tabs;
  public tabs: Tabs[] = [Tabs.Definition, Tabs.Preview];
  public selectedTab: Tabs = Tabs.Definition;
  public isPreviewDisabled = true;
  private definitionInputSubject = new Subject<void>();
  private destroy$ = new Subject<void>();
  public hasChanges: boolean = false;

  constructor(
    private functions: Functions,
    private authService: AuthService,
    private symbolService: SymbolService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PromptEditingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { prompt: Prompt },
    @Optional() private toastr?: ToastrService) {
      this.prompt = data.prompt;
    }

  ngOnInit(): void {
    this.symbolService.symbol$
      .pipe(takeUntil(this.destroy$))
      .subscribe((symbol) => {
        this.symbol = symbol;
      });

    this.definitionInputSubject
      .pipe(debounceTime(2000), takeUntil(this.destroy$))
      .subscribe(() => {
        this.putInputsInPrompt();
      });

    this.putInputsInPrompt();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleHeader() {
    this.openHeader = !this.openHeader;
  }

  toggleFooter() {
    this.openFooter = !this.openFooter;
  }

  async savePrompt() {
    const promptId = this.prompt.promptId;
    if (!this.prompt.target_ticker || !promptId) {
      const prompt = {
        ...this.prompt,
        target_ticker: this.symbol,
      };

      const profile = untracked(this.authService.userProfile)
      const subscription = this.authService.userSubscription;

      if ( profile.prompts >= subscription.maxPrompts ) {
        this.toastr?.error(
          'You have reached your maximum number of prompts. Delete an existing \
          prompt or upgrade to continue.',
          'Error saving prompt'
        );
        return Promise.reject("Cannot create additional prompts.");
      }

      this.hasOperation = true;
      httpsCallable(
        this.functions,
        'save_prompt'
      )(prompt)
        .then((result: any) => {
          result = result.data;
          this.prompt.promptId = result.promptId;
        })
        .catch((error) => {
          this.toastr?.error(error, 'Error saving prompt');
        })
        .finally(() => {
          this.hasOperation = false;

          // Close the dialog if the prompt was saved successfully
          // Handle error and show a message to the user if the prompt was not saved
          this.dialogRef.close();
        });
    } else {
      this.hasOperation = true;
      httpsCallable(
        this.functions,
        'update_prompt'
      )(this.prompt)
        .catch((error) => {
          this.toastr?.error(error, 'Error updating prompt');
        })
        .finally(() => {
          this.hasOperation = false;

          // Close the dialog if the prompt was saved successfully
          // Handle error and show a message to the user if the prompt was not saved
          this.dialogRef.close();
        });
    }
  }

  handleCancel(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { message: 'You have unsaved changes. Do you want to exit without saving?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) return;
      this.dialogRef.close();
    });
  }

  async putInputsInPrompt() {
    const data = {
      prompt: {
        ...this.prompt,
        targetTicker: this.symbol,
      },
      executionDate: this.getExecutionDate()
    };

    this.hasOperation = true;
    httpsCallable(
      this.functions,
      'preview_prompt'
    )(data)
      .then((result: any) => {
        this.promptWithInputs = result.data;
        this.isPreviewDisabled = false;
      })
      .catch((error) => {
        this.promptWithInputs = JSON.stringify(error);

        // No toastr as the error is displayed in the UI
        // this.toastr?.error(error, 'Error previewing prompt');
      })
      .finally(() => (this.hasOperation = false));
  }

  async executePrompt() {
    const data = {
      prompt: this.prompt,
      evalDate: this.getExecutionDate(),
    };

    this.hasOperation = true;
    this.selectedTabIndex = 1;
    httpsCallable(
      this.functions,
      'run_prompt'
    )(data)
      .then((result: any) => {
        this.executedPrompt = `Decision: ${result.data.output.decision}\nReasoning: ${result.data.output.reasoning}`;
      })
      .catch((error) => {
        this.executedPrompt = JSON.stringify(error);

        // No toastr as the error is displayed in the UI
        // this.toastr?.error(error, 'Error running prompt');
      })
      .finally(() => (this.hasOperation = false));
  }

  insertInput(item: any) {
    this.prompt.template_body += `${item.name}: \n${item.examples[0]}`;
    this.refreshPreview();
  }

  getMidnightDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  getExecutionDate() {
    const today = new Date();
    let date = new Date(today);

    switch (this.executionDate as TimeOption) {
      case 'today':
        Date;
        break;
      case 'yesterday':
        date.setDate(today.getDate() - 1);
        break;
      case 'last-week':
        date.setDate(today.getDate() - 7);
        break;
      case 'last-month':
        date.setMonth(today.getMonth() - 1);
        break;
      case 'last-quarter':
        date.setMonth(today.getMonth() - 3);
        break;
      case 'last-year':
        date.setFullYear(today.getFullYear() - 1);
        break;
    }

    return this.getMidnightDate(date);
  }

  refreshPreview(): void {
    this.isPreviewDisabled = true;
    this.definitionInputSubject.next();
    this.onFieldChange();
  }

  onFieldChange(): void {
    this.hasChanges = true;
  }
}

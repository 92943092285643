<h1 mat-dialog-title>Backtest Configuration</h1>
<mat-dialog-content>
  <app-backtest-config [promptId]="data.prompt.promptId"></app-backtest-config>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <span *ngIf="data.prompt.backtest_count > 0" class="warning">
    The existing backtest will be lost
  </span>
  <button mat-raised-button (click)="cancel()" [disabled]="hasOperation">
    Cancel
  </button>
  <button mat-raised-button color="primary" cdkFocusInitial (click)="run()" [disabled]="hasOperation">
    Run
  </button>
</mat-dialog-actions>

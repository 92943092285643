<div class="about-us-container">

  <section class="hero">
    <h1>Automate Your Analysis</h1>
    <div class="left-panel">
      <p>
        With a customizable GenAI investment universe screener that adapts to your needs.
      </p>
      <ul>
        <li>Integrate data from any vital source.</li>
        <li>Craft tailored, specific prompts yourself.</li>
        <li>Customize a dynamic, adaptive dashboard.</li>
        <li>One-click back-testing for historical performance analysis.</li>
      </ul>
      <p>
        Contact us: <a href="mailto:hello@logicflow.ai">hello&#64;logicflow.ai</a>
      </p>
    </div>
    <img src="assets/illustration-1.webp" alt="Customizable Investment Universe">
  </section>

  <section class="advantages">
    <div class="advantage-item">
      <h2>Custom Data Sources</h2>
      <p>Your data tells a story, and every story matters. Integrate data from any source that's crucial to your
        strategy—be it mainstream financial news, niche market blogs, or proprietary financial models. At GPT Analyst,
        we help you weave your data threads into a cohesive narrative that’s as broad or as detailed as you need.</p>
    </div>
    <div class="advantage-item">
      <h2>Dynamic Prompts Crafted by You</h2>
      <p>Why settle for generic insights? With GPT Analyst, you’re the architect of your inquiries. Craft prompts that
        delve deep into the specifics you care about, from nuanced market trends to complex risk assessments. Our
        platform empowers you to generate actionable insights that are tailored just for you.</p>
    </div>
    <div class="advantage-item">
      <h2>A Dashboard That Dares to Adapt</h2>
      <p>Your dashboard should be as dynamic as the markets you track. Customize your GPT Analyst dashboard to not only
        display what’s essential but also highlight opportunities and risks that others might miss. Set it up to capture
        real-time data flows, alert you about market shifts, or simply summarize your day’s most crucial analytics. You
        decide what’s front and center.</p>
    </div>
    <div class="advantage-item">
      <h2>Back-Testing at Your Fingertips</h2>
      <p>Unlock the power of historical performance with just one click. At GPT Analyst, we understand that the best way
        to predict future trends is by understanding past outcomes. Our built-in back-testing capability allows you to
        simulate how your analyses would have performed under historical market conditions. This feature not only
        enhances your trust in the data-driven insights but also refines your strategies by identifying patterns that
        consistently lead to success.</p>
    </div>
  </section>

  <section class="analyze">
    <h2>Analyze as Never Before</h2>
    <p>GPT Analyst empowers you to gain unprecedented insights from your investment data, tailored to your unique needs.
    </p>
  </section>

  <section class="team">
    <h2>Meet the Team</h2>
    <div class="team-member">
      <img src="assets/lucas.jpeg" alt="Dr. Lucas Fievet">
      <h3>Dr. Lucas Fievet</h3>
      <p>
        CEO - Over 15 years of experiences in delivering successful software and AI projects at companies
        like Zurich Insurance, Raiffeisen Schweiz, Migros Bank and Zurich Cantonal Bank.
      </p>
    </div>
    <div class="team-member">
      <img src="assets/andreas.jpeg" alt="Dr. Andreas Thomik">
      <h3>Dr. Andreas Thomik</h3>
      <p>
        CTO - Over 15 years of experience in technology and AI development. Former BCG consultant and successful
        founder.
      </p>
    </div>
    <div class="team-member">
      <img src="assets/cyril.png" alt="Dr. Cyril Misev">
      <h3>Dr. Cyril Misev</h3>
      <p>Tech Lead - Expert in AI systems and software architecture.</p>
    </div>
    <div class="team-member">
      <img src="assets/vladimir.jpeg" alt="Vladimir Adamov">
      <h3>Vladimir Adamov</h3>
      <p>Senior Front End Lead - Skilled in creating intuitive user interfaces.</p>
    </div>
    <div class="team-member">
      <img src="assets/faruk.jpeg" alt="Faruk Ohran">
      <h3>Faruk Ohran</h3>
      <p>Front End Developer - Specializes in responsive and adaptive front-end design.</p>
    </div>
    <div class="team-member">
      <img src="assets/nadine.jpeg" alt="Nadine How">
      <h3>Nadine How</h3>
      <p>Executive Assistant - All back-office operations.</p>
    </div>
  </section>

  <p class="contact-us">
    Contact us: <a href="mailto:hello@logicflow.ai">hello&#64;logicflow.ai</a>
  </p>

  <section class="experience">
    <h2>Our Services</h2>
    <p>
      Explore opportunities with us as your partner in Gen AI strategy consulting, integration for GPT Analyst, or as
      expert speakers, available at your convenience.
    </p>
  </section>
</div>

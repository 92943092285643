import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-ga-help',
  standalone: true,
  imports: [MatExpansionModule],
  templateUrl: './ga-help.component.html',
  styleUrl: './ga-help.component.scss'
})
export class GaHelpComponent {

}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Consent, ConsentService } from './consent.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-consent-dialog',
  templateUrl: './consent-dialog.component.html',
  styleUrls: ['./consent-dialog.component.scss']
})
export class ConsentDialogComponent {
  consent: Consent = {
    agreed: false,
    ageVerified: false
  };

  constructor(
    private consentService: ConsentService,
    private router: Router,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ConsentDialogComponent>) {}

  canSubmit(): boolean {
    return this.consent.agreed && this.consent.ageVerified;
  }

  async onSubmit(): Promise<void> {
    try{
      await this.consentService.setConsent(this.consent);
      this.dialogRef.close(true);
    } catch (error: any) {
      this.toastr?.error(error, 'Error Recording Consent');
    }
  }

  onCancel(): void {
    this.dialogRef.close(false);
    this.router.navigate(['not-authorized']);
  }
}

<h2 mat-dialog-title>Terms and Conditions</h2>
<mat-dialog-content>
  <p>
    The financial summaries and forecasts provided by GPT Analyst are for informational
    purposes only and do not constitute financial advice.
  </p>
  <p>
    We decline any responsibility for any losses or damages resulting from the use of our app.
    Your use of GPT Analyst is entirely at your own risk.
  </p>
  <p>
    You are responsible for performing your own due diligence and seeking
    professional financial advice before making any investment decisions
    based on the information provided by GPT Analyst.
  </p>
  <p>By using GPT Analyst, you agree to our terms and conditions and privacy policy.</p>
  <p>Please read and agree to the terms and conditions and privacy policy.</p>

  <a href="/terms-and-conditions" target="_blank">Read full Terms and Conditions</a><br>
  <a href="/privacy-policy" target="_blank">Read our Privacy Policy</a><br>
  <a href="/risk-warning" target="_blank">Read our Risk Warnings</a><br><br>

</mat-dialog-content>
<mat-dialog-actions>

  <mat-checkbox [(ngModel)]="consent.agreed">
    I agree to the terms and conditions, privacy policy and risk warnings.
  </mat-checkbox>

  <mat-checkbox [(ngModel)]="consent.ageVerified">I confirm I am over 18 years old</mat-checkbox>

  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button [disabled]="!canSubmit()" (click)="onSubmit()">Agree</button>
</mat-dialog-actions>

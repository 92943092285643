import { Component } from '@angular/core';

export interface BlogPost {
  id: number;
  title: string;
  excerpt: string;
  imageUrl: string;
  author: string;
  date: string;
  path: string;
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss'
})
export class BlogComponent {
  blogPosts: BlogPost[] = [{
    "id": 1,
    "title": "Analyzing Netflix's Stock with AI: A Case Study",
    "excerpt": "Explore how AI-driven financial modeling can predict Netflix's stock performance, yielding high returns and strategic insights.",
    "imageUrl": "/assets/blog-netflix.webp",
    "author": "Lucas Fiévet",
    "date": "2024-08-11",
    "path": "/blog/analyzing-netflix-stock"
}];
}

<div class="blog-overview-container">
  <div class="blog-tile" *ngFor="let post of blogPosts" [routerLink]="post.path">
    <img [src]="post.imageUrl" alt="{{ post.title }}" class="blog-image">
    <div class="blog-content">
      <h3 class="blog-title">{{ post.title }}</h3>
      <p class="blog-excerpt">{{ post.excerpt }}</p>
      <div class="blog-meta">
        <span class="blog-author">{{ post.author }}</span> |
        <span class="blog-date">{{ post.date }}</span>
      </div>
    </div>
  </div>
</div>

<div class="title-container" (click)="toggle()">
  <mat-icon>{{ searchSectionOpened ? 'expand_less' : 'expand_more' }}</mat-icon>
  <h2>{{getTitle()}}</h2>
</div>

<div *ngIf="searchSectionOpened" class="news-controls">
  <!-- <mat-form-field appearance="fill">
    <mat-label>Industry</mat-label>
    <mat-select [(ngModel)]="selectedIndustry" (selectionChange)="onIndustryChange($event.value)">
      <mat-option *ngFor="let industry of industries" [value]="industry">{{ industry }}</mat-option>
    </mat-select>
  </mat-form-field> -->

  <mat-form-field appearance="fill">
    <mat-label>Start Date</mat-label>
    <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate" (dateChange)="onStartDateChange()" />
    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker #startPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>End Date</mat-label>
    <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate" (dateChange)="onEndDateChange()" />
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Performance</mat-label>
    <mat-select [(ngModel)]="performance" (selectionChange)="onFilterChange()">
      <mat-option value="All">All</mat-option>
      <mat-option value="Winners">Winners</mat-option>
      <mat-option value="Losers">Losers</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="positionChange" (change)="onPositionChange()">
    Position Change
  </mat-checkbox>

  <!-- <mat-form-field appearance="fill">
    <mat-label>Search</mat-label>
    <input matInput [(ngModel)]="searchQuery" (input)="onSearch(searchQuery)" />
  </mat-form-field> -->
</div>

<div class="navigation" *ngIf="currentNews.length > 0">

  <mat-spinner *ngIf="loading$ | async" diameter="24"></mat-spinner>

  <button mat-icon-button (click)="previous()" [disabled]="isFirst" class="chevron-left">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button mat-icon-button (click)="next()" [disabled]="isLast" class="chevron-right">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="news-carousel">
  <div class="news-container">
    <div *ngIf="currentNews.length === 0 && !(loading$ | async)">
      <h3>No news found</h3>
    </div>
    <div *ngFor="let news of currentNews" class="news-tile" [routerLink]="getStockLink(news)" [class]="news.position">
      <h3>
        <span class="ticker" matTooltip="Stock name">{{ news.title }}</span>
        <div class="prompt-name" matTooltip="Prompt name">{{ news.prompt_name }}</div>
      </h3>
      <div class="prompt-status">
        <span class="return"
          matTooltip="Annualized return in excess of the stock return"
          [class.winner]="news.annualized_excess_return > 0"
          [class.looser]="news.annualized_excess_return < 0"
          [class.neutral]="news.annualized_excess_return == 0"
          [class.unavailable]="news.annualized_excess_return == undefined"
        >
          AR: {{ news.annualized_excess_return ? (news.annualized_excess_return > 0 ? '+' : '') : 'Not Available' }}{{ news.annualized_excess_return }}{{ news.annualized_excess_return ? '%' : '' }},
        </span>
        <span class="position" [class]="news.position" matTooltip="GPT Analyst position decision">
          Current Position: {{ news.position }}
        </span>
      </div>
      <p class="news-content">{{ news.content | slice: 0:(news.showMore ? 100000 : maxLength) }}
        <span *ngIf="!news.showMore && news.content.length > maxLength">...</span>
        <button mat-raised-button
                color="secondary"
                *ngIf="news.content.length > maxLength"
                class="show-more-button"
                (click)="news.showMore = !news.showMore; $event.stopPropagation()">
          {{ news.showMore ? 'Show less' : 'Show more' }}
        </button>
      </p>
      <div class="news-date">{{ news.date | date:'medium' }} ({{ news.date | date:'z' }})</div>
    </div>
  </div>
</div>

<div class="load-more-wrapper" *ngIf="currentNews.length > 0">

  <div class="navigation">
    <button mat-raised-button color="secondary" (click)="loadMore()" class="load-more" [hidden]="isLast">
      Load More
    </button>
    <button mat-icon-button (click)="previous()" [disabled]="isFirst" class="chevron-left" name="Previous News">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button (click)="next()" [disabled]="isLast" class="chevron-right" name="Next News">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

</div>

<div id="chart">
  <apx-chart
    [series]="series"
    [chart]="chart"
    [xaxis]="xaxis"
    [yaxis]="yaxis"
    [dataLabels]="dataLabels"
    [markers]="markers"
    [tooltip]="tooltip"
    [title]="title"
    [annotations]="annotations">
  </apx-chart>
</div>
<div class="date-picker-container">
  <mat-form-field appearance="fill">
    <mat-label>Date</mat-label>
    <input matInput [matDatepicker]="picker1" [formControl]="startDate" (dateChange)="onDateChange()">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
</div>

<h1>Privacy Policy</h1>

<h2>General</h2>
<p>
  The GPT Analyst website ("the Website") and the GPT Analyst mobile application ("the App"),
  collectively referred to as "the Service." We are committed to protecting your privacy.
  This Privacy Policy explains our practices regarding the collection, use, and disclosure of
  your information through the Service.
</p>

<h2>Consent to Use Information</h2>
<p>
  By accessing, using, or providing information to the Service, you accept the terms of this Privacy Policy and
  consent to our collection, use, and disclosure of your information as described here. We encourage you to review
  this policy periodically. If you disagree with any part of this policy, please discontinue use of the Service
  immediately.
</p>
<p>
  For additional guidance, please refer to our Terms and Conditions and Cookie Policy, which are incorporated by
  reference into this Privacy Policy.
</p>

<h2>Information We Collect</h2>
<h3>Non-Personal Information</h3>
<p>
  We collect Non-Personal Information which includes data like your geo-location, web request, browser type, and the
  pages you visit. This information is used for security, auditing, tracking usage, and managing affiliate
  calculations.
</p>
<p>
  <strong>Cookies:</strong> We use cookies to enhance your experience by remembering user preferences. Details about
  the use of cookies can be found in our Cookie Policy.
</p>
<p>
  <strong>Web Beacons:</strong> We use web beacons to monitor site usage and visitor behavior.
</p>

<h3>Personal Information</h3>
<p>
  Personal Information may include your name, gender, phone number, email address, mailing address, and payment
  information. This information is collected when you create an account, which is required for access to certain
  features of the Service.
</p>
<p>
  You may also sign up using a Third Party Account like Facebook or Google. This option requires you to authorize the
  collection of certain Personal Information stored in these accounts.
</p>
<p>
  We treat linked Non-Personal and Personal Information collectively as Personal Information, processed based on your
  consent, our contractual obligations, or our legitimate business interests.
</p>
<h2>Disclosure of Information</h2>
<p>
  Refer to the 'Sharing of Information' section in this policy to understand how your information may be
  shared.
</p>

<h2>Information Collection</h2>
<ul>
  <li><strong>Personal Information:</strong> We collect personal information such as your name, email address, and
    location through account registration and service usage.</li>
  <li><strong>Financial Data:</strong> We process the financial data you provide to deliver our services.</li>
  <li><strong>Usage Data:</strong> Information on how you interact with our services, such as your browsing history and
    device information.</li>
</ul>

<h2>Use of Information</h2>
<p>The collected information is used to:</p>
<ul>
  <li>Provide and manage the services you request.</li>
  <li>Improve and customize our services based on your interactions.</li>
  <li>Communicate with you, including sending updates and marketing offers, subject to your preferences.</li>
  <li>Conduct research and analytics to enhance service performance.</li>
</ul>

<h2>Sharing of Information</h2>
<p>We may share your information with:</p>
<ul>
  <li>Service providers and partners who assist us in service operation and delivery.</li>
  <li>Legal authorities when required by law or to protect our rights or the safety of users.</li>
  <li>Third parties in the event of a business transaction such as a merger or acquisition.</li>
</ul>

<h2>Your Rights</h2>
<p>You have the right to:</p>
<ul>
  <li>Access, update or delete the personal information we hold about you.</li>
  <li>Opt-out of receiving marketing communications at any time.</li>
  <li>Withdraw consent for data processing, where applicable.</li>
</ul>
<p>To exercise these rights, please contact us at lucas.fievet&#64;gmail.com.</p>

<h2>Data Security</h2>
<p>We implement security measures to protect your information from unauthorized access, alteration, and loss. However,
  no system is entirely secure, and we cannot guarantee the complete security of your information.</p>

<h2>Third-Party Services</h2>
<p>Our service may include links to third-party websites, whose privacy practices are not under our control. We are not
  responsible for the privacy or security practices of any third-party.</p>

<h2>Changes to This Policy</h2>
<p>We may update this policy to reflect changes to our information practices. If we make any material changes, we will
  notify you by email or by means of a notice on this site prior to the change becoming effective.</p>

<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, please contact us at:</p>
<p>Email: lucas.fievet&#64;gmail.com</p>
<p>Address: Butzenstrasse 130, 8041 Zürich</p>

<h2>Prompts</h2>

<mat-tab-group
  animationDuration="0ms"
  class="markets"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  (selectedIndexChange)="applyFilter(filterValues[$event])"
  [selectedIndex]="selectedIndex"
>
  @for (filterValue of filterValues; track filterValue) {
  <mat-tab [label]="filterValue">
    <!--Lazy Loaded -->
    <ng-template matTabContent>
      @if(!authService.user() && filterValue === 'Mine') {
      <div class="login">
        <p>Login to create and edit prompts</p>
        <button mat-raised-button [routerLink]="['/login']">Login</button>
      </div>
      }@else {
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container matColumnDef="expand" sticky>
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="toggleExpanded(element); $event.stopPropagation()"
            >
              @if (isExpanded[element.promptId]) {
              <mat-icon>keyboard_arrow_down</mat-icon>
              } @else {
              <mat-icon>keyboard_arrow_right</mat-icon>
              }
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name" sticky>
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let prompt">{{ prompt.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ar">
          <mat-header-cell *matHeaderCellDef mat-sort-header title="Annualized return of the prompt">
            <span>AR</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let prompt">
            {{  prompt.annualized_return ? (100 * prompt.annualized_return).toFixed(1) + '%' : 'N/A' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="are">
          <mat-header-cell *matHeaderCellDef mat-sort-header title="Annualized return of the prompt against the stock return">
            <span>AR+</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let prompt">
            {{  prompt.annualized_excess_return ? (100 * prompt.annualized_excess_return).toFixed(1) + '%' : 'N/A' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="backtest">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Backtest"
            class="mobile-hide-header"
          >
            Tested
          </mat-header-cell>
          <mat-cell *matCellDef="let prompt" class="mobile-narrow-column">
            <app-backtest-status
              [status]="prompt.backtestStatus"
              [progress]="prompt.progress"
            >
            </app-backtest-status>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="mobile-hide-header"
            >Active</mat-header-cell
          >
          <mat-cell
            *matCellDef="let prompt"
            (click)="toggleActive($event, prompt)"
            class="mobile-narrow-column"
          >
            @if(prompt.active) {
            <button
              mat-icon-button
              title="Disable"
              [disabled]="!prompt.isOwner"
            >
              <mat-icon class="prompt active">toggle_on</mat-icon>
            </button>
            }@else {
            <button mat-icon-button title="Enable" [disabled]="!prompt.isOwner">
              <mat-icon class="prompt inactive">toggle_off</mat-icon>
            </button>
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let prompt">
            <!--mobile actions-->
            <div class="mobile-actions">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <ng-container *ngIf="prompt.isOwner">
                  <button mat-menu-item (click)="edit(prompt)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="backtest(prompt)">
                    <mat-icon>play_circle_outline</mat-icon>
                    <span>Launch backtest</span>
                  </button>
                </ng-container>
                <button mat-menu-item (click)="copy($event, prompt)">
                  <mat-icon>content_copy</mat-icon>
                  <span>Copy</span>
                </button>
                <button
                  mat-menu-item
                  (click)="delete(prompt)"
                  *ngIf="prompt.isOwner"
                >
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>

            <!--desktop & tablet actions-->
            <div class="desktop-actions">
              @if(prompt.isOwner) {
              <button mat-icon-button (click)="edit(prompt)" title="Edit">
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                (click)="backtest(prompt)"
                title="Launch backtest"
              >
                <mat-icon>play_circle_outline</mat-icon>
              </button>
              } @else {
              <!-- <button mat-icon-button (click)="view(prompt)" title="View">
                            <mat-icon>visibility</mat-icon>
                          </button> -->
              }
              <button mat-icon-button (click)="copy($event, prompt)" title="Copy">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button
                mat-icon-button
                (click)="delete(prompt)"
                title="Delete"
                *ngIf="prompt.isOwner">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <mat-cell
            *matCellDef="let prompt"
            [attr.colspan]="displayedColumns.length"
          >
            <app-backtest-run [prompt]="prompt"></app-backtest-run>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.selected]="row.selected"
          [style.display]="displayFlex"
          (click)="select(row)"
        >
        </mat-row>
        <mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          [class.show]="isExpanded[row.promptId]"
          class="expandable-row"
        >
        </mat-row>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No prompts found. Create your own from scratch below.
          </td>
        </tr>
      </table>
      }
    </ng-template>
  </mat-tab>
  }
</mat-tab-group>

<div class="prompt-navigation-container">
  <button
    mat-raised-button
    color="primary"
    (click)="newPrompt()"
    class="add-prompt"
    title="Create new prompt"
  >
    New
  </button>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons
    (page)="onPage()"
  >
  </mat-paginator>
</div>

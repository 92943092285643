import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteTrackerService {
  private previousUrl: string = '/';

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/login') {
          this.previousUrl = event.url;
        }
      }
    });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }
}

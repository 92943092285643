<div id="chart">
  <apx-chart
    [series]="series"
    [chart]="chart"
    [xaxis]="xaxis"
    [plotOptions]="plotOptions"
    [legend]="legend"
    [responsive]="responsive"
    [fill]="fill"
    [title]="title">
  </apx-chart>
</div>

<div class="date-picker-container">
  <mat-form-field appearance="fill">
    <mat-label>Start Date</mat-label>
    <input matInput [matDatepicker]="picker1" [formControl]="startDate" (dateChange)="onDateChange()">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>End Date</mat-label>
    <input matInput [matDatepicker]="picker2" [formControl]="endDate" (dateChange)="onDateChange()">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
</div>

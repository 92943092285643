import { Component, effect } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '@angular/fire/auth';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  user: User | null = null;
  userProfile: any = {};
  isAdmin: boolean = false

  get subscription() {
    return this.authService.userSubscription ?? {};
  }

  constructor(private authService: AuthService) {
    effect(() => {
      this.user = authService.user() ?? null;
      this.userProfile = authService.userProfile() ?? {};
    });
  }
}

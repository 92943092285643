<h2 mat-dialog-title>
  <input
    matInput
    placeholder="Name"
    [(ngModel)]="prompt.name"
    (ngModelChange)="onFieldChange()"
  />
</h2>

<!-- MOBILE & TABLET -->
<mat-dialog-content class="container mobile">
  <div class="full-width">
    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <input
        matInput
        placeholder="Description"
        [(ngModel)]="prompt.description"
        (ngModelChange)="onFieldChange()"
      />
    </mat-form-field>

    <div class="tabs">
      @for(tab of tabs; track tab) {
      <button (click)="selectedTab = tab" [class.active]="tab === selectedTab">
        {{ tab }}
      </button>
      }
    </div>

    <section [class.show]="selectedTab === Tabs.Definition">
      <mat-form-field class="full-width">
        <mat-label>Definition</mat-label>
        <textarea
          matInput
          placeholder="Definition"
          [(ngModel)]="prompt.template_body"
          (input)="refreshPreview()"
          rows="5"
        ></textarea>
      </mat-form-field>

      <div>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Data Items </mat-panel-title>
            <mat-panel-description>
              Building blocks for your prompts
            </mat-panel-description>
          </mat-expansion-panel-header>

          <app-prompt-inputs (onInsert)="insertInput($event)"></app-prompt-inputs>
        </mat-expansion-panel>
      </div>

    </section>

    <section [class.show]="selectedTab === Tabs.Preview">
      <div class="prompt-actions">
        @if(hasOperation) {
        <div class="loader">
          <mat-spinner [diameter]="48"></mat-spinner>
        </div>
        }
      </div>

      <div class="prompt-preview">{{promptWithInputs}}</div>
      <div class="prompt-generation">{{executedPrompt}}</div>

    </section>
  </div>
</mat-dialog-content>

<!-- DESKTOP -->
<mat-dialog-content class="container desktop">
  <section>
    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <input
        matInput
        placeholder="Description"
        [(ngModel)]="prompt.description"
        (ngModelChange)="onFieldChange()"
      />
    </mat-form-field>

    <div class="prompt-toggle" (click)="toggleHeader()" >
      <mat-icon>{{ openHeader ? 'expand_less' : 'expand_more' }}</mat-icon>
      <h4>Header</h4>
    </div>
    <div *ngIf="openHeader" class="prompt-header">
      {{ prompt.template_header }}
    </div>

    <div class="prompt-toggle">
      <h4>Body</h4>
    </div>

    <!-- <app-prompt-editor [(content)]="prompt.template_body" class="editor"></app-prompt-editor> -->
    <mat-form-field class="full-width">
      <mat-label>Start writing your prompt</mat-label>
      <textarea
        matInput
        placeholder="Definition"
        [(ngModel)]="prompt.template_body"
        (input)="refreshPreview()"
      ></textarea>
    </mat-form-field>

    <div class="prompt-toggle footer" (click)="toggleFooter()" >
      <mat-icon>{{ openFooter ? 'expand_less' : 'expand_more' }}</mat-icon>
      <h4>Footer</h4>
    </div>
    <div *ngIf="openFooter" class="prompt-header">
      {{ prompt.template_footer }}
    </div>

    <div class="data-items">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Data Items </mat-panel-title>
          <mat-panel-description>
            Building blocks for your prompts
          </mat-panel-description>
        </mat-expansion-panel-header>

        <app-prompt-inputs (onInsert)="insertInput($event)"></app-prompt-inputs>
      </mat-expansion-panel>
    </div>
  </section>

  <section style="position: relative">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">

      <mat-tab label="Prompt">
        @if(hasOperation) {
        <div class="loader">
          <mat-spinner [diameter]="48"></mat-spinner>
        </div>
        } @else {
          <div class="prompt-preview">{{promptWithInputs}}</div>
        }
      </mat-tab>

      <mat-tab label="Generation">

        <div class="prompt-generation">{{executedPrompt}}</div>

        @if(hasOperation) {
        <div class="loader">
          <mat-spinner [diameter]="48"></mat-spinner>
        </div>
        }

      </mat-tab>

    </mat-tab-group>

  </section>
</mat-dialog-content>

<mat-dialog-actions align="end">

  <span *ngIf="hasChanges" class="warning">
    The existing back-test will be lost
  </span>

  <mat-select
    class="run-date"
    title="Run Date"
    [(value)]="executionDate"
    (selectionChange)="refreshPreview()">

    <mat-option value="today">Today</mat-option>
    <mat-option value="yesterday">Yesterday</mat-option>
    <mat-option value="last-week">Last week</mat-option>
    <mat-option value="last-month">Last month</mat-option>
    <mat-option value="last-quarter">Last quarter</mat-option>
    <mat-option value="last-year">Last year</mat-option>
  </mat-select>

  <button
    mat-stroked-button
    color="primary"
    (click)="executePrompt()"
    [disabled]="hasOperation"
    title="Send prompt to LLM and show result">
    Run
  </button>

  <button
    mat-stroked-button
    [disabled]="hasOperation"
    (click)="handleCancel()"
    title="Cancel the prompt editing. All changes are lost">
    Cancel
  </button>

  <button
    color="primary"
    mat-raised-button
    cdkFocusInitial
    (click)="savePrompt()"
    [disabled]="hasOperation">
    Save
  </button>

</mat-dialog-actions>

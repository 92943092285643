<footer class="footer">
  <div class="container">
    <div class="logo">GPT Analyst</div>
    <div class="disclaimer">
      <p>
        Risk Disclosure for GPT Analyst Project: Engaging with financial instruments and cryptocurrencies carries
        significant risks, including the possibility of losing part or all of your investment.
        Such ventures are not suitable for everyone, given the high volatility associated with
        cryptocurrency prices, which can be influenced by numerous external factors,
        including but not limited to economic, regulatory, and political variables.
        Utilizing leverage or trading on margin can amplify these risks further.
      </p>
      <p>
        Before you opt to invest in financial instruments or cryptocurrencies, it is critical to thoroughly understand
        the associated risks and costs. You should evaluate your investment goals, experience level,
        and risk tolerance carefully, and consider seeking advice from financial experts if necessary.
      </p>
      <p>Please note that the GPT Analyst Project does not guarantee the real-time accuracy or
        completeness of any data presented. Information, including prices, may not be directly
        from markets or exchanges but from market makers, thus might not reflect the actual
        market prices. These are indicative values meant for informational purposes only, and
        not intended for trading or investment decisions. Neither the GPT Analyst Project nor
        any data provider accepts liability for losses or damages arising from the use of this information.
      </p>
      <p>
        The use, storage, reproduction, display, modification, transmission, or distribution of
        data from the GPT Analyst Project without express written permission is strictly prohibited.
        All rights to intellectual property contained within the project are reserved by the data providers or
        exchanges.
      </p>
      <p>The GPT Analyst Project may receive compensation from advertisers featured on the platform.
        This compensation can influence the interaction with advertisements but does not impact
        the impartiality of the project's informational content.
      </p>
    </div>
    <div class="copyright">
      <div class="operated">
        <b>Operated by</b>
        <div>LogicFlow AG</div>
        <div>Butzenstrasse 130</div>
        <div>CH-8041 Zürich</div>
        <div>VAT No.CHE-445.455.310 MWST</div>
        <div>&copy; 2024 - All Rights Reserved.</div>
        <br>
      </div>
      <div class="conditions">
        <a href="#" onclick="cookieconsent.openPreferencesCenter();">Cookie preferences</a>
        <a routerLink="/terms-and-conditions">Terms And Conditions</a>
        <a routerLink="/privacy-policy">Privacy Policy</a>
        <a routerLink="/risk-warning">Risk Warning</a>
      </div>
    </div>
  </div>
</footer>

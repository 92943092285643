<!-- prompt-performance.component.html -->
<div class="container">
  <div id="chart">
    <apx-chart
      [series]="series"
      [chart]="chart"
      [xaxis]="xaxis"
      [yaxis]="yaxis"
      [dataLabels]="dataLabels"
      [title]="title"
      [plotOptions]="plotOptions">
    </apx-chart>
  </div>

  <div class="statistics">
    <p>Mean of Excess Return Changes: {{ mean.toFixed(2) }}</p>
    <p>Standard Deviation of Excess Return Changes: {{ standardDeviation.toFixed(2) }}</p>
  </div>

  <div class="date-picker-container">
    <mat-form-field appearance="fill">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="picker1" [formControl]="startDate" (dateChange)="onDateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="picker2" [formControl]="endDate" (dateChange)="onDateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>

</div>

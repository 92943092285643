import {
  Component,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { collection, onSnapshot, query, Unsubscribe } from '@angular/fire/firestore';
import { db } from 'src/environments/environment';
import { last, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'admin-prompts-overview',
  templateUrl: './prompts-overview.component.html',
  styleUrl: './prompts-overview.component.scss',
})
export class PromptsOverviewComponent implements AfterViewInit, OnDestroy {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'symbol',
    'ownerId',
    'promptId',
    'active',
    'runId',
    'lastUpdate',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  @Output() onInsert = new EventEmitter<any>();
  private destroy$ = new Subject<void>();
  private promptOverviewListener: Unsubscribe | undefined;

  constructor(private datePipe: DatePipe) {}

  ngAfterViewInit() {
    const q = query(collection(db, "admin", "overview", "prompts"))
    this.promptOverviewListener = onSnapshot(q, (querySnapshot) => {
      const result: any[] = [];
      querySnapshot.forEach((doc) => {
        const data: any = doc.data();
        let lastUpdate = data.lastPredictionDate;
        lastUpdate = this.datePipe.transform(data.lastPredictionDate, 'yyyy-MM-dd HH:mm:ss');
        result.push({
          ...data,
          lastUpdate
        });
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.promptOverviewListener?.();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }
}

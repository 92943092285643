import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private titleService: Title, private metaService: Meta) {
    // Set the title of the page
    this.titleService.setTitle('GPT Analyst');

    // Set meta tags for the page
    this.metaService.addTags([
      {
        name: 'description',
        content:
          'Explore real-time stock insights and smart financial news powered by AI and machine learning. ' +
          'Get a competitive edge with automated investment analysis.'
      },
      {
        name: 'keywords',
        content:
          'Financial Analysis Tools, Stock Market Insights, AI Financial Advisor, ' +
          'Smart Financial News, AI Stock Analysis, Real-Time Stock Insights, Machine Learning Finance, ' +
          'Automated Investment Analysis, Quantitative Finance Tools, Market Trend Predictions'
      }
    ]);
  }
}

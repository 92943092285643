<mat-form-field class="full-width">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
</mat-form-field>

<mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="value">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Current Value</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.exampleValue }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Parameters</mat-header-cell>
    <mat-cell *matCellDef="let item" [innerHtml]="patternToDescription(item.pattern)"></mat-cell>
  </ng-container>

  <ng-container matColumnDef="backtestable">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Back-Testable</mat-header-cell>
    <mat-cell *matCellDef="let item"></mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions" stickyEnd>
    <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <button mat-icon-button (click)="onInsert.emit(item)">
        <mat-icon>add_diamond</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>

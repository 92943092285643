<h1>Terms and Conditions</h1>
<p>Last Updated: 24/07/2024</p>
<p>These terms and conditions ("Terms") govern your access to and use of the GPT Analyst services, which include our
  website, mobile applications, and any other linked pages, features, or content offered from time to time by GPT
  Analyst ("we", "us", or "our").</p>

<h2>Acceptance of Terms</h2>
<p>By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, do not
  use our services. We reserve the right to modify or replace these Terms at any time. You are advised to review this
  page periodically for any changes. Notice of any changes will be provided via email or in-app notifications.</p>

<h2>Disclaimer of Warranties and Liabilities</h2>
<p>The information provided by GPT Analyst is for informational purposes only and does not constitute financial advice.
  The app is not responsible for any losses or damages resulting from the use of the information provided. Forecasts and
  summaries are generated using AI and should not be relied upon as the sole basis for investment decisions.</p>

<h2>User Responsibilities</h2>
<p>Users should perform their own due diligence and seek professional advice before making any investment decisions.
  Users are responsible for any actions they take based on the information provided by the app.</p>

<h2>Data Privacy and Security</h2>
<p>We detail the types of data we collect, how it is used, and how it is protected in our Privacy Policy, which is
  incorporated by reference into these Terms. By using our services, you agree to our use, collection, and disclosure of
  personally identifiable information in accordance with our Privacy Policy. We comply with GDPR (for Europe), CCPA (for
  California, USA), and other relevant data protection regulations.</p>

<h2>User Consent</h2>
<p>By using the app, you agree to the terms and conditions and privacy policy. You provide explicit consent for us to
  process your data in accordance with these policies.</p>

<h2>Registration and Account Security</h2>
<p>To access certain features of our services, you may be required to register for an account. You agree to provide
  accurate, current, and complete information during the registration process and to update such information to keep it
  accurate, current, and complete. You are responsible for safeguarding your password and other authentication
  credentials. You agree not to disclose your credentials to any third party and to notify us immediately of any
  unauthorized use of your account.</p>

<h2>Limitations of Use</h2>
<p>You agree to use our services only for lawful purposes and in a way that does not infringe the rights of, restrict,
  or inhibit anyone else's use and enjoyment of the services. Prohibited behavior includes harassing or causing distress
  or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of
  dialogue within our services. Misuse of the app, such as automated scraping or sharing of forecasts, is not permitted.
</p>

<h2>Modification of Terms</h2>
<p>We reserve the right to modify these Terms at any time. Users will be notified of any changes via email or in-app
  notifications. Continued use of the services after changes have been made constitutes acceptance of the new Terms.</p>

<h2>Intellectual Property</h2>
<p>The services and their original content, features, and functionality are and will remain the exclusive property of
  GPT Analyst and its licensors. Our trademarks and trade dress may not be used in connection with any product or
  service without the prior written consent of GPT Analyst. This includes terms related to the use of any third-party
  content or data.</p>

<h2>Links to Other Web Sites</h2>
<p>Our Service may contain links to third-party web sites or services that are not owned or controlled by GPT Analyst.
  GPT Analyst has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any
  third-party web sites or services.</p>

<h2>Termination</h2>
<p>We may terminate or suspend your access to our services immediately, without prior notice or liability, for any
  reason whatsoever, including, without limitation, if you breach the Terms. All provisions of the Terms which by their
  nature should survive termination shall survive termination, including, without limitation, ownership provisions,
  warranty disclaimers, indemnity, and limitations of liability.</p>

<h2>Disclaimer and Non-Waiver of Rights</h2>
<p>GPT Analyst makes no guarantees, representations, or warranties of any kind as regards the website and associated
  technology. Any purportedly applicable warranties, terms, and conditions are excluded to the fullest extent permitted
  by law. Your use of the services is at your sole risk. Our services are provided on an "AS IS" and "AS AVAILABLE"
  basis. GPT Analyst does not waive any rights by the failure to enforce any of these terms and conditions.</p>

<h2>Jurisdiction and Governing Law</h2>
<p>These Terms shall be governed and construed in accordance with the laws of Switzerland, without regard to its
  conflict of law provisions. This ensures compliance with the most significant markets, including Switzerland, the EU,
  and the US.</p>

<h2>Contact Us</h2>
<p>If you have any questions about these Terms, please contact us at: lucas.fievet&#64;gmail.com</p>

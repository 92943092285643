<header class="header">
  <div class="container">
    <div class="logo-container">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <a class="logo" routerLink="/" title="Home">
        <img src="../../../assets/logo_white_no_text.svg" width="64" height="48" alt="Logo">
      </a>
      <a class="logo-text" routerLink="/">
        <div>GPT Analyst</div>
        <span class="slogan">Decoding Markets with AI</span>
      </a>
    </div>
    <div class="login">
      <!-- Placeholder for the login component -->
      <app-login></app-login>
    </div>
  </div>
</header>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #snav [mode]="'over'" fixedInViewport="true" fixedTopGap="0">
    <mat-icon class="close" (click)="snav.toggle()">close</mat-icon>
    <mat-nav-list>
      <a mat-list-item routerLink="." (click)="snav.toggle()">
        <mat-icon>home</mat-icon>
        Overview
      </a>
      <a mat-list-item routerLink="help" (click)="snav.toggle()">
        <mat-icon>description</mat-icon>
        Documentation
      </a>
      <!-- <a mat-list-item routerLink="pricing" (click)="snav.toggle()">
        <mat-icon>credit_card</mat-icon>
        Pricing
      </a> -->
      <!-- <a mat-list-item routerLink="blog" (click)="snav.toggle()">
        <mat-icon>rss_feed</mat-icon>
        Blog
      </a> -->
      <!-- <a mat-list-item routerLink="about" (click)="snav.toggle()">
        <mat-icon>contact_support</mat-icon>
        Contact
      </a> -->
      @if(authService.user(); as user) {
        <a mat-list-item routerLink="profile" (click)="snav.toggle()">
          <mat-icon>person</mat-icon>
          Profile
        </a>
        <a mat-list-item routerLink="." (click)="authService.logout(); snav.toggle()">
          <mat-icon>logout</mat-icon>
          Logout
        </a>
      }@else {
        <a mat-list-item routerLink="login" (click)="snav.toggle()">
          <mat-icon>login</mat-icon>
          Login
        </a>
      }
      <a mat-list-item routerLink="admin" (click)="snav.toggle()" *ngIf="isAdmin">
        <mat-icon>admin_panel_settings</mat-icon>
        Admin
      </a>
      <a mat-list-item routerLink="statistics" (click)="snav.toggle()" *ngIf="isAdmin">
        <mat-icon>analytics</mat-icon>
        Statistics
      </a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>

import { Component, OnInit } from '@angular/core';
import { collection, getDocs, limit, query, where } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { orderBy } from 'firebase/firestore';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexXAxis,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexPlotOptions
} from 'ng-apexcharts';
import { db } from 'src/environments/environment';

@Component({
  selector: 'app-prompt-performance-change',
  templateUrl: './prompt-performance-change.component.html',
  styleUrl: './prompt-performance-change.component.scss'
})
export class PromptPerformanceChangeComponent implements OnInit {
  public startDate = new FormControl<Date>(new Date());
  public endDate = new FormControl<Date>(new Date());
  public bins = new FormControl<number>(10);
  public series: ApexAxisChartSeries = [];
  public mean: number = 0;
  public standardDeviation: number = 0;

  public chart: ApexChart = {
    type: 'bar',
    height: 480,
  };

  public xaxis: ApexXAxis = {
    type: 'category',
    title: { text: 'Excess Return Change' }
  };

  public yaxis: ApexYAxis = {
    title: { text: 'Frequency' }
  };

  public plotOptions: ApexPlotOptions = {
    bar: {
      distributed: true,
      borderRadius: 10,
      horizontal: false,
      borderRadiusApplication: 'end', // 'around', 'end'
      borderRadiusWhenStacked: 'last', // 'all', 'last'
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      }
    }
  };

  public dataLabels: ApexDataLabels = {
    enabled: false
  };

  public title: ApexTitleSubtitle = {
    text: 'Excess Return Change Distribution',
  };

  constructor() {}

  ngOnInit() {
    let today = new Date();
    const lastWeek = new Date(today.setDate(today.getDate() - 7));
    this.startDate.setValue(lastWeek);

    today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    this.endDate.setValue(yesterday);

    this.loadChartData();
  }

  loadChartData() {
    const startDate = this.startDate.value || new Date();
    const endDate = this.endDate.value || new Date();
    const bins = this.bins.value || 10;
    this.getPredictionsByDate(startDate, endDate).then(data => {
      const processedData = this.processData(data, bins);
      this.series = processedData.series;
      this.mean = processedData.mean;
      this.standardDeviation = processedData.standardDeviation;
    });
  }

  getPredictionsByDate(startDate: Date, endDate: Date): Promise<any> {
    const promptsRef = collection(db, "relevancies");

    const startDateStart = new Date(startDate);
    startDateStart.setHours(0, 0, 0, 0);
    const startDateEnd = new Date(startDate);
    startDateEnd.setHours(23, 59, 59, 999);
    const startConstraints = [
      where('date', '>=', startDateStart.toISOString().split(".")[0] + "Z"),
      where('date', '<=', startDateEnd.toISOString().split(".")[0] + "Z"),
      orderBy('date', 'desc'),
      limit(10000)
    ];

    const endDateStart = new Date(endDate);
    endDateStart.setHours(0, 0, 0, 0);
    const endDateEnd = new Date(endDate);
    endDateEnd.setHours(23, 59, 59, 999);
    const endConstraints = [
      where('date', '>=', endDateStart.toISOString().split(".")[0] + "Z"),
      where('date', '<=', endDateEnd.toISOString().split(".")[0] + "Z"),
      orderBy('date', 'desc'),
      limit(10000)
    ];

    const startQuery = query(promptsRef, ...startConstraints);
    const endQuery = query(promptsRef, ...endConstraints);

    return Promise.all([getDocs(startQuery), getDocs(endQuery)]).then(([startSnapshot, endSnapshot]) => {
      const startData = startSnapshot.docs.map(doc => ({ ...doc.data(), date: startDate }));
      const endData = endSnapshot.docs.map(doc => ({ ...doc.data(), date: endDate }));
      return { startData, endData };
    });
  }

  processData(data: { startData: any[], endData: any[] }, bins: number): { series: ApexAxisChartSeries, mean: number, standardDeviation: number } {
    const startDataMap = new Map(data.startData.map(item => [item.ticker, item]));
    const changes: number[] = [];

    data.endData.forEach(item => {
      const startItem = startDataMap.get(item.ticker);
      if (startItem) {
        const yChange = 100 * (item.annualized_excess_return - startItem.annualized_excess_return);
        changes.push(yChange);
      }
    });

    const filteredChanges = this.filterOutliers(changes);
    const mean = this.calculateMean(filteredChanges);
    const standardDeviation = this.calculateStandardDeviation(filteredChanges, mean);
    const histogramData = this.calculateHistogram(filteredChanges, bins);

    return {
      series: [{
        name: 'Frequency',
        type: 'bar',
        data: histogramData,
      }],
      mean,
      standardDeviation
    };
  }

  filterOutliers(data: number[]): number[] {
    const sortedData = [...data].sort((a, b) => a - b);
    const q1 = this.calculatePercentile(sortedData, 25);
    const q3 = this.calculatePercentile(sortedData, 75);
    const iqr = q3 - q1;

    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    return data.filter(val => val >= lowerBound && val <= upperBound);
  }

  calculatePercentile(sortedData: number[], percentile: number): number {
    const index = (percentile / 100) * (sortedData.length - 1);
    const lower = Math.floor(index);
    const upper = lower + 1;
    const weight = index - lower;

    if (upper >= sortedData.length) return sortedData[lower];
    return sortedData[lower] * (1 - weight) + sortedData[upper] * weight;
  }

  calculateMean(data: number[]): number {
    const sum = data.reduce((acc, val) => acc + val, 0);
    return sum / data.length;
  }

  calculateStandardDeviation(data: number[], mean: number): number {
    const variance = data.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / data.length;
    return Math.sqrt(variance);
  }

  calculateHistogram(data: number[], bins: number): { x: string; y: number }[] {
    const min = Math.min(...data);
    const max = Math.max(...data);
    const binWidth = (max - min) / bins;

    const histogram = Array(bins).fill(0).map((_, i) => {
      const lowerBound = min + i * binWidth;
      const upperBound = lowerBound + binWidth;
      const count = data.filter(val => val >= lowerBound && val < upperBound).length;
      return { x: `${lowerBound.toFixed(1)} - ${upperBound.toFixed(1)}`, y: count };
    });

    // Handle edge case where max value falls into the last bin
    histogram[histogram.length - 1].y += data.filter(val => val === max).length;

    return histogram;
  }

  onDateChange(): void {
    if (this.startDate.value && this.endDate.value) {
      this.loadChartData();
    }
  }

  onBinsChange(): void {
    if (this.startDate.value && this.endDate.value) {
      this.loadChartData();
    }
  }
}

import { Component } from '@angular/core';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-ga-home',
  templateUrl: './ga-home.component.html',
  styleUrl: './ga-home.component.scss'
})
export class GaHomeComponent {
  constructor(_: SeoService) {}
}

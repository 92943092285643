import { Injectable, Optional, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { RouteTrackerService } from './route-tracker.service';
import { doc, getDoc, onSnapshot, Unsubscribe } from '@angular/fire/firestore';
import { db } from 'src/environments/environment';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userProfileListener: Unsubscribe | null = null;

  routeTrackerService = inject(RouteTrackerService);
  router = inject(Router);

  public auth: any = undefined;
  public user = signal<User | null>(null);
  public userId = '';
  public userProfile = signal<any | null>(null);
  public userSubscription: any = undefined;

  constructor(@Optional() auth?: Auth, @Optional() private toastr?: ToastrService) {
    this.auth = auth ?? getAuth();

    onAuthStateChanged(
      this.auth,
      (user) => {
        this.user.set(user);
        this.userId = user?.uid || '';
        const returnUrl = this.routeTrackerService.getPreviousUrl();
        if (user) {
          this.router.navigateByUrl(returnUrl);
          this.userProfileListener = this.loadProfile();
          this.loadSubscription(this.userProfile());
        } else {
          this.userProfileListener?.();
        }
      },
      (error) => {
        console.error('Error listening to auth state changes:', error);
        this.toastr?.error(JSON.stringify(error), 'Error in authentication');
      }
    );
  }

  ngOnDestroy(): void {
    this.userProfileListener?.();
  }

  logout(): Promise<void> {
    return signOut(this.auth);
  }

  loadProfile(): Unsubscribe {
    return onSnapshot(doc(db, 'users', this.userId), (doc) => {
      if (doc.exists()) {
        const uData = doc.data();
        this.userProfile.set(uData);
        this.loadSubscription(uData);
      } else {
        this.userProfile.set(null);
      }
    });
  }

  loadSubscription(profile: any): void {
    const subscriptionName = profile?.subscription;
    console.log('Loading subscription for profile', profile);

    if (profile) {
      console.log('Reading subscription', subscriptionName);
      getDoc(doc(db, 'subscriptions', subscriptionName)).then((sDoc) => {
        if (sDoc.exists()) {
          this.userSubscription = sDoc.data();
        } else {
          console.log('Subscription does not exists');
        }
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { collection, getDocs, limit, query, where } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { orderBy } from 'firebase/firestore';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexFill,
  ApexLegend,
  ApexResponsive,
  ApexPlotOptions
} from 'ng-apexcharts';
import { db } from 'src/environments/environment';


@Component({
  selector: 'app-prompt-positions',
  templateUrl: './prompt-positions.component.html',
  styleUrls: ['./prompt-positions.component.scss']
})
export class PromptPositionsComponent implements OnInit {
  public startDate = new FormControl<Date>(new Date());
  public endDate = new FormControl<Date>(new Date());
  public series: ApexAxisChartSeries = [];
  public chart: ApexChart = {
    type: 'bar',
    height: 350,
    stacked: true,
    toolbar: {
      show: true
    },
    zoom: {
      enabled: true
    }
  };
  public xaxis: ApexXAxis = {
    type: 'datetime',
    labels: {
      formatter: function(val) {
        return new Date(val).toLocaleDateString('en-US', {
          weekday: 'short',
          day: 'numeric',
          month: 'short'
        });
      }
    },
    tickPlacement: 'on'
  };
  public title: ApexTitleSubtitle = {
    text: 'Daily Prediction Counts'
  };
  public plotOptions: ApexPlotOptions = {
    bar: {
      borderRadius: 10,
      horizontal: false,
      borderRadiusApplication: 'end', // 'around', 'end'
      borderRadiusWhenStacked: 'last', // 'all', 'last'
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      }
    }
  };
  public legend: ApexLegend = {
    position: 'right',
    offsetY: 40
  };
  public fill: ApexFill = {
    opacity: 1
  };
  public responsive: ApexResponsive[] = [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0
      }
    }
  }];

  constructor() {}

  ngOnInit() {
    const today = new Date();
    const lastWeek = new Date(today.setDate(today.getDate() - 7));
    this.startDate.setValue(lastWeek);

    this.loadChartData();
  }

  loadChartData() {
    const startDate = this.startDate.value || new Date();
    const endDate = this.endDate.value || new Date();
    this.getPredictionsByDate(startDate, endDate).then(data => {
      let chartData = this.processData(data);
      this.series = [
        {
          name: 'Long',
          data: chartData.long.map((item: any) => ({ x: item.date, y: item.count }))
        },
        {
          name: 'Cash',
          data: chartData.cash.map((item: any) => ({ x: item.date, y: item.count }))
        },
        {
          name: 'Short',
          data: chartData.short.map((item: any) => ({ x: item.date, y: item.count }))
        }
      ];
      console.log(this.series);
    });
  }

  getPredictionsByDate(startDate: Date, endDate: Date): Promise<any[]> {
    const promptsRef = collection(db, "relevancies");
    let constraints: any[] = [
      where('date', '>=', startDate.toISOString().split(".")[0] + "Z"),
      where('date', '<=', endDate.toISOString().split(".")[0] + "Z"),
      orderBy('date', 'desc'),
      limit(10000)
    ];

    const q = query(promptsRef, ...constraints);

    const relevancies: any[] = [];
    return getDocs(q).then((snapshot) => {
      snapshot.forEach((doc) => {
        relevancies.push(doc.data());
      });
      return relevancies;
    });
  }

  processData(data: any[]) {
    let grouped = data.reduce((acc, item) => {
      const date = new Date(item.date).toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = { long: 0, cash: 0, short: 0 };
      }
      acc[date][item.position]++;
      return acc;
    }, {});

    let result: any = { long: [], cash: [], short: [] };
    Object.keys(grouped).forEach(date => {
      result.long.push({ date: date, count: grouped[date].long });
      result.cash.push({ date: date, count: grouped[date].cash });
      result.short.push({ date: date, count: grouped[date].short });
    });

    return result;
  }

  onDateChange(): void {
    if (this.startDate.value && this.endDate.value) {
      this.loadChartData();
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-backtest-status',
  templateUrl: './backtest-status.component.html',
  styleUrl: './backtest-status.component.scss'
})
export class BacktestStatusComponent {
  @Input() status: string | null = null;
  @Input() progress: number = 0;
}

<app-header></app-header>

<div class="main-content-wrapper">
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>

<a routerLink="/feedback" id="feedback" title="Feedback">
  <mat-icon>feedback</mat-icon>
</a>

import {
  Component,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { SymbolService } from 'src/app/services/symbol.service';

@Component({
  selector: 'app-prompt-inputs',
  templateUrl: './prompt-inputs.component.html',
  styleUrl: './prompt-inputs.component.scss',
})
export class PromptInputsComponent implements AfterViewInit, OnDestroy {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'name',
    'description',
    'backtestable',
    'actions',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  @Output() onInsert = new EventEmitter<any>();
  private destroy$ = new Subject<void>();

  constructor(
    private functions: Functions,
    private symbolService: SymbolService)
  {}

  ngAfterViewInit() {
    this.symbolService.symbol$
      .pipe(takeUntil(this.destroy$))
      .subscribe((symbol) => {
        if (!symbol) return;

        httpsCallable(
          this.functions,
          'get_fields'
        )({ symbol }).then((result: any) => {
          this.dataSource = new MatTableDataSource<any>(result.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  patternToDescription(pattern: string): string {
    if (pattern.includes('<_date_from>')) {
      return '|Date from|Date to|Symbol<br>Example last seven days: -7|-0|AAPL';
    } else if (pattern.includes('<symbol>')) {
      return '|Symbol<br>Example: |AAPL';
    } else {
      return '';
    }
  }
}

<mat-form-field class="full-width">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="MSFT" #input>
</mat-form-field>

<mat-table [dataSource]="dataSource" matSort  matSortActive="lastUpdate" matSortDirection="desc">

  <ng-container matColumnDef="symbol">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Symbol</mat-header-cell>
    <mat-cell *matCellDef="let item"><a routerLink="/ticker/{{ item.symbol }}">{{ item.symbol }}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="ownerId">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Owner</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.ownerId }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="promptId">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Prompt Id</mat-header-cell>
    <mat-cell *matCellDef="let item"><a routerLink="/ticker/{{ item.symbol }}/{{ item.promptId }}">{{ item.promptId }}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="runId">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Run Id</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.runId }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="active">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Active</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.active }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastUpdate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Last update date</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.lastUpdate }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions" stickyEnd>
    <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <button mat-icon-button (click)="onInsert.emit(item)">
        <mat-icon>add_diamond</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>

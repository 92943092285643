import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RunConfiguration } from 'src/app/model/run.model';
import { PromptService } from 'src/app/services/prompt.service';

const WEEK_DAY_OPTIONS = [
  { value: 'MO', label: 'Monday' },
  { value: 'TU', label: 'Tuesday' },
  { value: 'WE', label: 'Wednesday' },
  { value: 'TH', label: 'Thursday' },
  { value: 'FR', label: 'Friday' },
  { value: 'SA', label: 'Saturday' },
  { value: 'SU', label: 'Sunday' }
];

const MONTH_DAY_OPTIONS = [...Array(31).keys()].map(i => ({ value: i + 1, label: `${i + 1}` }));

@Component({
  selector: 'app-backtest-config',
  templateUrl: './backtest-config.component.html',
  styleUrls: ['./backtest-config.component.scss']
})
export class BacktestConfigComponent {
  @Input() promptId: string = '';

  evalFreqOptions = [
    { value: 'd', label: 'Day' },
    { value: 'w', label: 'Week' },
    { value: 'm', label: 'Month' }
  ];

  daysOptions: any = WEEK_DAY_OPTIONS;

  runConfigForm: FormGroup = this.fb.group({
    runTime: ['08:00', Validators.required],
    backtestConfig: this.fb.group({
      eval_count: [12, Validators.required],
      eval_freq: ['w', Validators.required],
      eval_day: [{ value: 'MO', disabled: false }, Validators.required],
      trigger_date: [null]
    }),
    llmConfig: this.fb.group({
      temperature: new FormControl({
        value: 0.0,
        disabled: true
      }, [Validators.required, Validators.min(0.0), Validators.max(1.0)]),
      model: new FormControl({
        value: 'gpt-3.5-turbo',
        disabled: true
      }, [Validators.required]),
    })
  });

  constructor(
    private fb: FormBuilder,
    private promptService: PromptService) {

    this.evalFreqChanged(); // Setup listener for changes
  }

  run() {
    if (this.runConfigForm?.valid) {
      const config: RunConfiguration = this.runConfigForm.value;
      return this.promptService.backtest(this.promptId, config);
    }

    return Promise.reject('Invalid form');
  }

  evalFreqChanged(): void {
    const backtestConfig = this.runConfigForm.get('backtestConfig');
    if (!backtestConfig) return;

    const freqField = backtestConfig.get('eval_freq');
    const dayField = backtestConfig.get('eval_day');
    if (!freqField || !dayField) return;

    freqField.valueChanges.subscribe(val => {
      if (val === 'm') {
        this.daysOptions = MONTH_DAY_OPTIONS;
        dayField.setValue(1);
        dayField.enable();
      } else if (val === 'w') {
        this.daysOptions = WEEK_DAY_OPTIONS;
        dayField.setValue('1');
        dayField.enable();
      } else {
        this.daysOptions = [];
        dayField.disable();
        dayField.setValue('');
      }
    });
  }
}
